import { graphql, useQuery } from 'react-apollo'
import { gql } from 'apollo-boost'
import {
  Layout, Table,
  Menu, Breadcrumb,
} from 'antd'
import React, { useEffect, useState, Fragment } from 'react'
import { Link } from 'react-router-dom'
import _ from 'lodash'

import department from '../data/department.json'

const { Header, Content, Sider } = Layout
const queryRegistration = gql`query getRegistration($slug: String) {
  registrationMany(filter: {
    AND:[{slug: $slug},
    {profile : {
    	userType:"บุคคลในหน่วยงาน"
    }}]
    
  },limit:100000) {
    _id
    userId
    bib
    profile {
      name
      lastName
      email
      phone
      birthDate
      display
      province
      idCard
      height
      weight
      gender
      amphoe
      district
      userType
      department
      
    }
    results {
      userId
      results
      date
    }
  }
}`

const DepartBoard = (props) => {
  const { data = { registrationMany: [] }, loading } = useQuery(queryRegistration, {
    variables: { slug: 'nscth' },
  })
  const dd = fetch('https://us-central1-thairun-bot-vr.cloudfunctions.net/app/graphql', {
    body: JSON.stringify({
      query: `getRegistration($slug: String) {
        registrationMany(filter: {
          AND:[{slug: $slug},
          {profile : {
            userType:"บุคคลในหน่วยงาน"
          }}]
          
        },limit:100000) {
          _id
          userId
          bib
          profile {
            name
            lastName
            email
            phone
            birthDate
            display
            province
            idCard
            height
            weight
            gender
            amphoe
            district
            userType
            department
            
          }
          results {
            userId
            results
            date
          }
        }
      }`,
      operationName: 'getRegistration',
      variables: { slug: 'nscth' },
    }),
  })
  console.log(data.registrationMany)
  const departmentgroup = _(data.registrationMany)
    .groupBy('profile.department')
    .map((items, name) => ({
      name, count: items.length, items, sums: _.sumBy(items, (o) => { if (o.results !== null) { return _.sumBy(o.results.results) } return 0 }),
    }))
    .value()
  console.log(departmentgroup)
  departmentgroup.forEach((part, index, theArray) => {
    department.forEach((p, i, tArray) => {
      if (theArray[index].name === tArray[i].name && theArray[index].name !== 'null') {
        theArray[index].amount = tArray[i].amount
        theArray[index].avgDistance = theArray[index].sums / tArray[i].amount
        theArray[index].attendance = theArray[index].count / tArray[i].amount
      }
    })
  }, departmentgroup)

  // department.map(e => (
  //   setDepartment(depart => [...depart, {
  //     name: e.name,
  //     amount: e.amount,
  //     amountDepartment: departmentgroup.map(item => (item.name === e.name ? item.count : 0)),
  //     // distanceTotal: registrationMany.map(i => (i.results === null ? 0 : i.results.results.reduce((result, number) => result + number))),
  //     // avgDistance: registrationMany.map(i => (i.results === null ? 0 : i.results.results.reduce((result, number) => result + number))) / e.amount,
  //     // attendance: departmentgroup.map(item => ((item.name === e.name) ? item.count : '')) / e.amount,
  //   }])
  // ))

  const departmentgroupDesc = _.sortBy(departmentgroup, ['avgDistance']).reverse()
  const columns = [
    {
      title: 'หน่วยงาน',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'จำนวนบุคลากรทั้งหมดแต่ละหน่วยงาน',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'จำนวนผู้สมัครของแต่ละหน่วยงาน',
      dataIndex: 'count',
      key: 'count',
    },
    {
      title: 'ระยะสะสมรวมทั้งหมดของแต่ละ หน่วยงาน',
      dataIndex: 'sums',
      key: 'distanceTotal',
    },
    {
      title: 'ระยะสะสมของแต่ละหน่วยงานเฉลี่ยของ ต่อบุคลากรทั้งหมดแต่ละหน่วยงาน',
      dataIndex: 'avgDistance',
      key: 'avgDistance',
      defaultSortOrder: 'descend',
      sorter: (a, b) => (a.count - b.count),
    },
    {
      title: 'อัตราการเข้าร่วมของบุคลากร แต่ละ หน่วยงาน',
      dataIndex: 'attendance',
      key: 'attendance',
    },
    {
      title: 'ลำดับของหน่วยงาน',
      dataIndex: 'rank',
      key: 'rank',
      render: record => (departmentgroupDesc.findIndex(obj => obj.results === record) + 1),
    },
  ]

  return (
    <Fragment>
      <h2>ภาพรวมหน่วยงาน</h2>
      <Table
        dataSource={departmentgroup}
        columns={columns}
        rowKey="_id"
        pagination={{ pageSize: 10 }}
        size="middle"
            // onChange={this.onTableChange}
        loading={loading}
        bordered
      />
    </Fragment>

  )
}

export default graphql(queryRegistration, { name: 'registrationMany', options: { variables: { slug: 'nscth' } } })(DepartBoard)
