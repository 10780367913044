import gql from 'graphql-tag'

export default gql`
query getRegistration($slug: String) {
  reportCountUserType(slug:$slug) {
    _id
    distance
    count
    malecount
    femalecount
    avgDistanceRegis
    avgDistanceAll
  }
}
`
