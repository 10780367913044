import React, { useState, Fragment } from 'react'
import gql from 'graphql-tag'
import { graphql, useQuery, useMutation } from 'react-apollo'
import { Link } from 'react-router-dom'
import {
  Layout, Table, Button, Modal, Icon,
} from 'antd'
import moment from 'moment'
import 'moment/locale/th'

moment.locale('th')

function IndividualResults({ match: { params } }) {
  const [removeSubmission] = useMutation(mutationRemoveSubmission)
  const [visible, setVisible] = useState(false)
  const [picnamemodal, setPicnamemodal] = useState('')
  const { data, loading, refetch } = useQuery(queryGetSubmission, {
    variables: {
      id: params.registrationId,
    },
  })

  const columns = [
    {
      title: 'Image Ref.',
      dataIndex: 'imageRef',
      key: 'imageRef',
      render: text => (text ? <img src={text} alt={text} width={50} onClick={e => showModal(e, text)} /> : 'Image ref not found.'),
      width: 200,
      ellipsis: true,
      className: 'headercolor',
    },
    {
      title: 'Submission distance',
      dataIndex: 'distance',
      key: 'distance',
      ellipsis: true,
      className: 'headercolor',
    },
    {
      title: 'Progress distance',
      dataIndex: 'progressDistance',
      key: 'progressDistance',
      ellipsis: true,
      render: text => text.toFixed(2),
      className: 'headercolor',
    }, {
      title: 'Submission time',
      dataIndex: 'createdAt',
      key: 'createAt',
      ellipsis: true,
      render: text => moment(text).format('LLLL'),
      className: 'headercolor',
    }, {
      title: 'Options',
      render: ({ _id, registrationId }) => (
        <Button
          type="danger"
          onClick={() => Modal.confirm({
            title: 'คุณต้องการที่จะลบข้อมูลหรือไม่ ?',
            onOk() {
              handleRemoveSubmission({
                resultId: _id.toString(), registrationId: registrationId.toString(), removeSubmission, refetch,
              })
            },
          })}
        >
          <Icon type="delete" />
        </Button>
      ),
      className: 'headercolor',
    },
  ]

  const handleRemoveSubmission = async ({
    registrationId, resultId, removeSubmission, refetch,
  }) => {
    await removeSubmission({
      variables: {
        registrationId,
        resultId,
      },
    })
    refetch()
  }
  const showModal = (e, picname) => {
    setVisible(true)
    setPicnamemodal(picname)
  }

  const handleOk = (e) => {
    setVisible(false)
  }

  const handleCancel = (e) => {
    setVisible(false)
  }

  if (loading) return <Table loading />
  if (!data) return <div>Registration not found!</div>
  const { registrationByIds } = data
  const vrSubmissions = registrationByIds[0].vrSubmissions.filter(e => e.distance !== null)

  if (vrSubmissions.length === 0) return <Table hasData={null} />
  const lastIndex = vrSubmissions.length - 1
  const idLastSubmission = vrSubmissions[lastIndex]._id

  return (
    <Fragment>
      <div style={{ textAlign: 'center' }}>
        <Table
          rowKey={({ _id }) => _id}
          dataSource={vrSubmissions}
          columns={columns}
        />
      </div>
      <Modal
        visible={visible}
        onCancel={handleCancel}
        width={400}
        centered
        footer={[
          <Button onClick={handleCancel}>
            Close
          </Button>,
        ]}
      >
        <img src={picnamemodal} alt={picnamemodal} style={{ width: '100%' }} />
      </Modal>
      <Link to="/individual-searchs/nscth">
        <Button>ย้อนกลับ</Button>
      </Link>
    </Fragment>

  )
}

const mutationRemoveSubmission = gql`mutation removeSubmission($registrationId: String!, $resultId: String!) {
  removeSubmission(registrationId: $registrationId, resultId: $resultId)
}`

const queryGetSubmission = gql`query getSubmissionByRegId($id: [MongoID]!){
  registrationByIds(_ids: $id) {
    _id
    userId
    slug
    date
    status
    bib
    profile {
      name
      lastName
      email
      phone
      birthDate
      display
      idCard
      height
      weight
      houseNo
      villageNo
      village
      lane
      road
      zipCode
      gender
      shirtSize
      province
      amphoe
      district
      userType
      department
    }
    vrSubmissions {
      _id
      bib
      slug
      registrationId
      distance
      progressDistance
      createdAt
      imageRef
    }
  }
}`

export default IndividualResults
