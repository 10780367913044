import {
  Button, Row, Col,
} from 'antd'
import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

const GengerReport = props => (
  <Fragment>
    <Row>
      <Col span={8}>
        <Link to="/individual">
          <Button size="large">รวม</Button>
        </Link>
      </Col>
      <Col span={8}>
        <Link to="/malereport">
          <Button size="large">ชาย</Button>
        </Link>
      </Col>
      <Col span={8}>
        <Link to="/femalereport">
          <Button size="large">หญิง</Button>
        </Link>
      </Col>
    </Row>
  </Fragment>

)
export default GengerReport
