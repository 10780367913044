import React, { useState } from 'react'
import { Layout, Input, Select, Button, Table, Form } from 'antd'
import gql from 'graphql-tag'
import { graphql, useQuery } from 'react-apollo'
import { Link } from 'react-router-dom'

function IndividualSearch({match: { params }}) {
  const [ typeSearch, setTypeSearch ] = useState('')
  const [ text, setText ] = useState('')
  const [ isSearch , setIsSearch ] = useState(false)

  const handleSearch = () => {
    console.log({ typeSearch, text, slug: params.slug})
  }
  // const isSearch = typeSearch !== "" && text !== ""
  return <Layout>
    <Layout.Content>
      <h1>Individual search.</h1>
      <div style={{ padding: 20 }}>
        <Form layout="inline">
          <Form.Item label="Text Search">
            <Input 
              style={{ width: 400 }}
              placeholder="Enter search"
              onChange={e => {
                const { value } = e.target
                setText(value)
                setIsSearch(false)
              }}
            />
          </Form.Item>
          <Form.Item label="Type Search">
            <Select
              onChange={(e) => setTypeSearch(e)}
              style={{ width: 150 }}
            >
              {
                listTypeSearch.map(item => <Select.Option key={item} value={item.key}>{item.value}</Select.Option>)
              }
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" onClick={() => setIsSearch(true)}>Search</Button>
          </Form.Item>
        </Form>
      </div>
      {isSearch && <div>
        <Registrations typeSearch={typeSearch} text={text} slug={params.slug}/>
      </div>}
    </Layout.Content>
  </Layout>
}

function Registrations({slug, typeSearch, text}) {
  const { data, loading } = useQuery(querySearchRegistrations, {
    variables: {
      slug,
      typeSearch,
      text
    }
  })

  if (loading) return <Table loading />
  if (!data) return <Table hasData={null} />

  const { searchRegistrations } = data
  return (
    <Table 
      rowKey={({_id}) => _id}
      columns={columns}
      dataSource={searchRegistrations}
    />
  )
}


const listTypeSearch = [
  {
    key: 'registrationId',
    value: 'Registration ID',
  }, {
    key: 'name',
    value: 'Name',
  }, {
    key: 'idCard',
    value: 'ID Card',
  },{
    key: 'bib',
    value: 'Bib Number',
  }, {
    key: 'phone',
    value: 'Phone', 
  }
]

const columns = [
  {
    title: 'RegistrationId',
    dataIndex: '_id',
    key: '_id',
    render: (text, { _id }) => <Link to={`/individual-result/${_id}`}>{_id}</Link>
  }, {
    title: 'Bib Number',
    dataIndex: 'bib',
    key: 'bib'
  }, {
    title: 'Name',
    dataIndex: 'profile.name',
    key: 'profile.name'
  }, {
    title: 'Submission',
    dataIndex: 'vrSubmissions',
    key: 'vrSubmissions',
    render: (i) => `${i.length} time(s)`
  }
]

const querySearchRegistrations = gql`query searchRegistration($slug: String!, $typeSearch: String!, $text: String) {
  searchRegistrations(slug: $slug, typeSearch: $typeSearch, text: $text) {
    _id
    userId
    slug
    date
    status
    bib
    vrSubmissions {
      bib
      slug
      registrationId
      distance
      progressDistance
      createdAt
      imageRef
    }
    profile {
      name
      lastName
      email
      phone
      birthDate
      display
      idCard
      height
      weight
      houseNo
      villageNo
      village
      lane
      road
      zipCode
      gender
      shirtSize
      province
      amphoe
      district
      userType
      department
    }
  }
}`

export default IndividualSearch