import gql from 'graphql-tag'

export default gql`
query ($slug: String, $gender: String, $limit: Int) {
    progressDistanceMost(slug: $slug, gender: $gender, limit: $limit){
        _id
        progressDistance
        date
        registrationId
        registration {
          userId
          slug
          date
          status
          bib
          profile {
            name
            lastName
            email
            phone
            birthDate
            display
            idCard
            height
            weight
            houseNo
            villageNo
            village
            lane
            road
            zipCode
            gender
            shirtSize
            province
            amphoe
            district
            userType
            department
          }
        }
    }
}
`
