import gql from 'graphql-tag'

export default gql`
query progressDistance($slug: String, $progressDistance: Int,$typeUser: [String], $gender: String, $limit: Int) {
  progressDistance(slug: $slug, progressDistance: $progressDistance,typeUser: $typeUser, gender: $gender, limit: $limit) {
    _id
    progressDistance
    date
    registrationId
    registration {
      userId
      slug
      date
      status
      bib
      profile {
        name
        lastName
        email
        phone
        birthDate
        display
        idCard
        height
        weight
        houseNo
        villageNo
        village
        lane
        road
        zipCode
        gender
        shirtSize
        province
        amphoe
        district
        userType
        department
      }
    }
  }
}
`
