import gql from 'graphql-tag'

export default gql`
query recentDownload($slug: String!, $resource: String!){
  recentDownload(
    filter: {
      slug: $slug,
      resource: $resource
    }
  ) {
    _id
    status
    progress
    url
    createdAt
    updatedAt
  }
}
`