import gql from 'graphql-tag'

export default gql`
query getRegistration($slug: String) {
  reportCountProvince(slug:$slug) {
    _id
    distance
    count
  }
}
`
