import gql from 'graphql-tag'

export default gql`
query getRegistration($slug: String) {
  reportCountDepartment(slug:$slug) {
    distance
    count
    department
  }
}
`
