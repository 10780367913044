import { graphql } from 'react-apollo'
import { gql } from 'apollo-boost'
import {
  Layout, Table,
  Menu, Breadcrumb,
} from 'antd'
import React, { useEffect, Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import * as compose from 'lodash.flowright'
import _ from 'lodash'

import department from '../data/department.json'

const { Header, Content, Sider } = Layout
const queryRegistration = gql`query getRegistration($slug: String) {
  registrationMany(filter: {
    AND:[{slug: $slug},
    {profile : {
    	userType:"บุคคลในหน่วยงาน"
    }}]
    
  }) {
    _id
    userId
    bib
    profile {
      name
      lastName
      email
      phone
      birthDate
      display
      province
      idCard
      height
      weight
      gender
      amphoe
      district
      userType
      department
      
    }
    results {
      userId
      results
      date
    }
  }
}`

const DepartmentBoard = (props) => {
  const { registrationMany: { loading, registrationMany = [] } } = props
  const [departments, setDepartment] = useState([{
    total: 0,
    regisTotal: 0,
    distanceTotal: 0,
    avgDistance: 0,
    attendance: 0,
  }])

  useEffect(() => {
    const Total = _.sumBy(department, 'amount')
    const distancetotal = _.sum(registrationMany.map(e => (e.results === null ? 0 : e.results.results.reduce((result, number) => result + number))))
    setDepartment([{
      total: _.sumBy(department, 'amount'),
      regisTotal: registrationMany.length,
      distanceTotal: distancetotal,
      avgDistance: distancetotal / Total,
      attendance: registrationMany.length / Total,
    }])
  }, [])

  const columns = [
    {
      title: 'จำนวนบุคลากรกระทรวงฯทั้งหมด',
      dataIndex: 'total',
      key: 'total',
    },
    {
      title: 'จำนวนผู้สมัครของกระทรวงฯ',
      dataIndex: 'regisTotal',
      key: 'regisTotal',
    },
    {
      title: 'ระยะสะสมรวมทั้งหมดของบุคลากร กระทรวงฯ',
      dataIndex: 'distanceTotal',
      key: 'distanceTotal',
    },
    {
      title: 'ระยะสะสมเฉลี่ยของต่อบุคลากร กระทรวงฯ ทั้งหมด',
      dataIndex: 'avgDistance',
      key: 'avgDistance',
    },
    {
      title: 'อัตราการเข้าร่วมของบุคลากรกระทรวงฯ',
      dataIndex: 'attendance',
      key: 'attendance',
    },
  ]

  const columns2 = [
    {
      title: 'หน่วยงาน',
      dataIndex: 'name',
      key: '',
    },
    {
      title: 'จำนวนบุคลากรทั้งหมดแต่ละหน่วยงาน',
      dataIndex: 'amount',
      key: '',
    },
    {
      title: 'จำนวนผู้สมัครของแต่ละหน่วยงาน',
      dataIndex: '',
      key: '',
    },
    {
      title: 'ระยะสะสมรวมทั้งหมดของแต่ละ หน่วยงาน',
      dataIndex: 'distanceTotal',
      key: 'distanceTotal',
    },
    {
      title: 'ระยะสะสมของแต่ละหน่วยงานเฉลี่ยของ ตอ่บุคลากรทั้งหมดแต่ละหน่วยงาน',
      dataIndex: 'avgDistance',
      key: 'avgDistance',
    },
    {
      title: 'อัตราการเข้าร่วมของบุคลากร แต่ละ หน่วยงาน',
      dataIndex: 'attendance',
      key: 'attendance',
    },
    {
      title: 'ลำดับของหน่วยงาน',
      dataIndex: '',
      key: '',
    },
  ]
  return (
    <Fragment>
      <h2>ภาพรวมกระทรวง</h2>
      <Table
        dataSource={departments}
        columns={columns}
        rowKey="_id"
        pagination={{ pageSize: 10 }}
        size="middle"
            // onChange={this.onTableChange}
        loading={loading}
        bordered
      />
    </Fragment>

  )
}

export default graphql(queryRegistration, { name: 'registrationMany', options: { variables: { slug: 'nscth' } } })(DepartmentBoard)
