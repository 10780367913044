import { Query, graphql, useQuery } from 'react-apollo'
import {
  Layout, Table,
  Menu, Breadcrumb, Icon, Button, Row, Col,
} from 'antd'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import provinces from '../data/provinces.json'

const { Header, Content, Sider } = Layout

const columns = [
  {
    title: 'ลำดับ',
    dataIndex: 'name.th',
    key: 'id',
    render: record => (provinces.findIndex(obj => obj.name.th === record) + 1),

  },
  {
    title: 'ชื่อจังหวัด',
    dataIndex: 'name.th',
    key: 'th',
  },
  {
    title: 'จำนวนประชากร',
    dataIndex: 'population',
    key: 'population',
    render: record => (`${record.toLocaleString() }  คน`),
  },
]
const ProvinceDetail = props => (
  <Fragment>
    {/* <Button style={{ float: 'right', margin: 10 }} type="primary">เพิ่มจังหวัด</Button> */}
    <Table
      dataSource={provinces}
      columns={columns}
      rowKey="_id"
      pagination={{ pageSize: 10 }}
      size="middle"
            // onChange={this.onTableChange}
      bordered
    />
  </Fragment>

)
export default ProvinceDetail
