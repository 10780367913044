import { graphql, useQuery } from 'react-apollo'
import {
  Button, Row, Col,
  Layout, Table,
} from 'antd'
import React, { Fragment, useEffect, useState } from 'react'

import department from '../data/department.json'
import RegistrationDepartCount from '../graphql/queries/getRegistrationDepart'

const { Content } = Layout

const columns = [
  {
    title: 'สำนักงาน',
    dataIndex: 'department',
    key: 'department',
  },
  {
    title: 'ยอดผู้สมัคร',
    dataIndex: 'count',
    key: 'count',
    defaultSortOrder: 'descend',
    sorter: (a, b) => (a.count - b.count),
  },
]

const DepartmentReport = (props) => {
  const [position, setPosition] = useState(0)
  const [numload, setNumload] = useState(8)
  const [loadstatus, setLoadstatus] = useState(true)
  const [procount, setProcount] = useState([])
  const {
    data = { registrationCount: [] }, loading, error, fetchMore,
  } = useQuery(RegistrationDepartCount, {
    variables: { slug: 'nscth', department: '' },
  })
  if (loading && loadstatus) {
    return <p>loading...</p>
  }
  const onFetchMore = async (depart) => {
    setPosition(position + 1)
    if (position === numload) {
      setLoadstatus(false)
    }
    await fetchMore({
      query: RegistrationDepartCount,
      variables: { slug: 'nscth', department: depart },
      fetchPolicy: 'cache-and-network',
      updateQuery: (previousResult, { fetchMoreResult }) => {
        // ...previousResult,
        // // Add the new matches data to the end of the old matches data.
        // registrationCount: [
        //   previousResult.registrationCount,
        //   fetchMoreResult.registrationCount,
        // ],
        setProcount(procount => [...procount, { department: depart, count: fetchMoreResult.registrationCount }])
      },
    })
  }

  if (loadstatus && !loading) {
    onFetchMore(department[position].name)
  }

  if (error) {
    console.log('error', error)
    return <p>error</p>
  }

  // useEffect(() => {
  //   province.forEach(async (item) => {
  //     await fetchMore({
  //       query: RegistrationProvinceCount,
  //       variables: { slug: 'nscth', province: item.name },
  //       fetchPolicy: 'cache-and-network',
  //       updateQuery: (previousResult, { fetchMoreResult }) => ({
  //         ...previousResult,
  //         registrationCount: [
  //           ...previousResult.registrationMany,
  //           ...fetchMoreResult.registrationMany,
  //         ],
  //       }),
  //     })
  //   })
  // }, data.registrationCount)
  // <Fragment>
  //   <Row>
  //     <Col span={8}>
  //       <Link to="/individuallead">
  //         <Button size="large">ระดับบุคคล</Button>
  //       </Link>
  //     </Col>
  //     <Col span={8}>
  //       <Link to="/departmentselect">
  //         <Button size="large">ระดับหน่วยงาน</Button>
  //       </Link>
  //     </Col>
  //     {/* <Col span={8}>
  //       <Button size="large">ระดับจังหวัด</Button>
  //     </Col> */}
  //     <Col span={8}>
  //       <Link to="/provinceboard">
  //         <Button size="large">province board</Button>
  //       </Link>
  //     </Col>
  //   </Row>
  // </Fragment>
  // const onTableChange = (pagination) => {
  //   // console.log('params', pagination, filters, sorter, extra, skip)
  //   setNumload(numload + 20)
  //   if (pagination.current < 9 && position < 77) {
  //     setLoadstatus(true)
  //     onFetchMore(province[position].name.th)
  //   }
  //   // console.log(pagination.current)
  //   // onFetchMore()
  // }

  // console.log(procount)
  return (

    <Layout>
      <Content>
        <div style={{ background: '#fff', padding: 24, minHeight: 'calc(100vh - 64px - 69px)' }}>
          <Table
              // rowSelection={rowSelection}
            dataSource={procount}
            columns={columns}
            rowKey="province"
            pagination={{ pageSize: 10 }}
            size="middle"

            // onChange={onTableChange}
            // loading={loadstatus}
            bordered
          />
        </div>
      </Content>
    </Layout>
  )
}
export default DepartmentReport
