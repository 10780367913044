import React, { Fragment, useEffect, useState } from 'react'
import { graphql, useQuery } from 'react-apollo'
import {
  Button, Row, Col,
  Layout, Table,
} from 'antd'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import department from '../data/department.json'
import vhv from '../data/vhv.json'

import reportUserTypeCount from '../graphql/queries/reportCountUserType'

import ProvinceReport from './nolayoutProvinceReport'

const {
  Header, Footer, Sider, Content,
} = Layout

const LeaderBoard = (props) => {
  // const {
  //   data = { reportCountProvince: [] }, loading, error,
  // } = useQuery(reportProvinceCount, {
  //   variables: { slug: 'nscth' },
  // })
  const [totalregis, setTotalregis] = useState(0)
  const [totaldepartment, setTotaldepartment] = useState(0)
  const [totalvhv, setTotalvhv] = useState(0)
  const [totalall, setTotalall] = useState(0)
  const [status, setStatus] = useState(true)
  const columns = [
    {
      title: 'ประเภทผู้สมัคร',
      dataIndex: '_id',
      align: 'center',
      className: 'headercolor',
      render: record => (record === 'บุคคลในหน่วยงาน' ? <Link to="/departmentboard">{record}</Link> : record),
    },
    {
      title: 'จำนวนผู้สมัคร (คน)',
      dataIndex: 'count',
      key: '',
      defaultSortOrder: 'descend',
      align: 'center',
      className: 'headercolor',
      sorter: (a, b) => (a.isTotal || b.isTotal ? 0 : (a.count - b.count)),
      render: record => (`${record.toLocaleString() } `),
  
    },
    {
      title: 'เพศชาย (เปอร์เซนต์)',
      dataIndex: 'malecount',
      key: 'malecount',
      defaultSortOrder: 'descend',
      align: 'center',
      className: 'headercolor',
      sorter: (a, b) => (a.isTotal || b.isTotal ? 0 : (a.malecount - b.malecount)),
      render: (record, index) => (`${Math.round(record / index.count * 100) } `),
  
    },
    {
      title: 'เพศหญิง (เปอร์เซนต์)',
      dataIndex: 'femalecount',
      key: 'femalecount',
      defaultSortOrder: 'descend',
      align: 'center',
      className: 'headercolor',
      sorter: (a, b) => (a.isTotal || b.isTotal ? 0 : (a.femalecount - b.femalecount)),
      render: (record, index) => (`${Math.round(record / index.count * 100) } `),
  
    },
    {
      title: 'จำนวนทั้งหมด',
      dataIndex: '',
      key: 'allcount',
      defaultSortOrder: 'descend',
      align: 'center',
      className: 'headercolor',
      render: (record, index) => {
        if (index._id === 'บุคคลในหน่วยงาน') {
          return `${totaldepartment.toLocaleString()}`
        }
        else if (index._id === 'อสม') {
          return `${'1,101,369'}`
        }
        else if(index._id === 'บุคคลทั่วไป'){
          return `${index.count.toLocaleString()}`
        }
        else {
          return `${totalall.toLocaleString()}`
        }
      },
    },
    {
      title: 'ระยะวิ่งสะสม (กม.)',
      dataIndex: 'distance',
      key: '',
      align: 'center',
      className: 'headercolor',
      sorter: (a, b) => (a.isTotal || b.isTotal ? 0 : (a.distance - b.distance)),
      render: record => (`${record.toLocaleString(undefined, { minimumFractionDigits: 2 }) } `),
    },
    {
      title: 'ระยะทางเฉลี่ยต่อคนลงทะเบียน (กม./คน)',
      dataIndex: 'avgDistanceRegis',
      key: 'avgDistanceRegis',
      align: 'center',
      className: 'headercolor',
      sorter: (a, b) => (a.isTotal || b.isTotal ? 0 : (a.avgDistanceRegis - b.avgDistanceRegis)),
      render: record => (`${Number(record).toFixed(2)} `),
    },
    {
      title: 'ระยะทางเฉลี่ยต่อจำนวนทั้งหมด (กม./คน)',
      dataIndex: 'distance',
      key: 'avgDistanceAll',
      align: 'center',
      className: 'headercolor',
      sorter: (a, b) => (a.isTotal || b.isTotal ? 0 : (a.avgDistanceRegis - b.avgDistanceRegis)),
      render: (record, index) => {
        if (index._id === 'บุคคลในหน่วยงาน') {
          return `${Number(record / totaldepartment).toFixed(2)}`
        }
        if (index._id === 'อสม') {
          return `${Number(record / 1101369).toFixed(2)}`
        }
        if (index._id === 'บุคคลทั่วไป') {
          return `${Number(record / index.count).toFixed(2)}`
        } else{
          return `${Number(record / totalall).toFixed(2)}`
        }
      },
    },
  ]
  const {
    data = { reportCountUserType: [] }, loading,
  } = useQuery(reportUserTypeCount, {
    variables: { slug: 'nscth' },
  })
  const locale = {
    emptyText: 'fetching data',
  }

  let reportSumAll = {
    _id: 'รวม',
    count: 0,
    distance: 0,
    malecount: 0,
    femalecount: 0,
    avgDistanceRegis: 0,
  }
  if (!loading) {
    const total = data.reportCountUserType.reduce((totalRecord, record) => ({
      count: totalRecord.count + record.count,
      distance: totalRecord.distance + record.distance,
      malecount: totalRecord.malecount + record.malecount,
      femalecount: totalRecord.femalecount + record.femalecount,
      avgDistanceRegis: totalRecord.avgDistanceRegis + record.avgDistanceRegis,
      avgDistanceAll: totalRecord.avgDistanceAll + record.avgDistanceAll,
    }), {
      count: 0,
      distance: 0,
      malecount: 0,
      femalecount: 0,
      avgDistanceRegis: 0,
      avgDistanceAll: 0,
    }) // { count, distance }
    // console.log({ total })
    if (status) {
      const departtotal = _.sumBy(department, 'amount')
      const vhvtotal = _.sumBy(vhv, 'population')
      setTotaldepartment(departtotal)
      setTotalvhv(vhvtotal)
      console.log(vhvtotal)
      console.log(_.find(data.reportCountUserType, {'_id':'บุคคลทั่วไป'}))
      const recuser = _.find(data.reportCountUserType, {'_id':'บุคคลทั่วไป'})
      setTotalall(departtotal + recuser.count + 1101369)
      setStatus(false)
    }
    reportSumAll = {
      _id: 'รวม',
      count: total.count,
      distance: total.distance,
      malecount: total.malecount,
      femalecount: total.femalecount,
      avgDistanceRegis: total.distance / total.count,
      avgDistanceAll: total.avgDistanceAll,
      isTotal: true,
    }
  }
  const reportCountAll = [...data.reportCountUserType, reportSumAll]

  return (
    <Layout>
      <Table
        // style={{ marginTop: '-50px' }}
          // rowSelection={rowSelection}
        dataSource={reportCountAll}
        columns={columns}
        locale={locale}
        rowKey="_id"
        pagination={false}
        // size="small"
          // onChange={onTableChange}
        loading={loading}
        bordered
      />
    </Layout>
  )
}
export default LeaderBoard
