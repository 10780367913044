import { ApolloProvider } from 'react-apollo'

import {
  BrowserRouter, Route, Switch, Redirect,
} from 'react-router-dom'
import React from 'react'
import ReactDOM from 'react-dom'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { ApolloLink } from 'apollo-link'

import firebase from './firebase'

import './index.css'
import './App.css'
import App from './App'

// import Result from './pages/result'
// import Report from './pages/report'
// import Index from './pages/index'
// import Activity from './pages/activity'
// import Province from './pages/provinces'
// import ProvinceDetail from './pages/provinceDetail'
import LeaderBoard from './pages/LeaderBoards'
import Amphoe from './pages/amphoeCount'
import District from './pages/districtCount'
import DepartCount from './pages/departmentCount'
import IndividualResults from './pages/IndividualResults'
import IndividualSearch from './pages/IndividualSearch'
// import IndividualReport from './pages/individualReport'
// import ProvinceBoard from './pages/provinceBoard'
// import IndividualLeader from './pages/individualLeader'
// import IndividualBoard from './pages/individualBoard'
// import DepartmentBoard from './pages/departmentBoard'
// import IndividualReportSelect from './pages/individualReportSelect'
// import DepartmentSelect from './pages/departmentSelect'
// import DepartBoard from './pages/departBoard'
// import GenderReport from './pages/genderReport'
// import MaleReport from './pages/maleReport'
// import FemaleReport from './pages/femaleReport'
import PrivatePage from './pages/PrivatePage'
import LoginPage from './pages/LoginPage'

// import { AuthProvider, checkTokenExpired } from './contexts/AuthContext'
import { AuthProvider } from './contexts/Auth'

import * as serviceWorker from './serviceWorker'

console.log('REACT_APP_GRAPHQL_SERVER', process.env.REACT_APP_GRAPHQL_SERVER)
// const authMiddleware = new ApolloLink((operation, forward) => {
//   operation.setContext(({ headers = {} }) => {
//     const { token, user } = checkTokenExpired(localStorage.getItem('token'))
//     if (token && user) {
//       return {
//         headers: {
//           ...headers,
//           Authorization: `Bearer ${token}`,
//         },
//       }
//     }
//     return { headers }
//   })
//   return forward(operation)
// })
const client = new ApolloClient({
  uri: process.env.REACT_APP_TRACKING_GRAPHQL_CLIENT,
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        console.error({ graphQLErrors })
      }
      if (networkError) {
        console.error({ networkError })
        // if (networkError.statusCode === 401) {
        //   removeToken()
        // }
      }
    }),
    // authMiddleware,
    new HttpLink({
      uri: process.env.REACT_APP_GRAPHQL_SERVER,
    }),
    // new HttpLink({
    //   uri: process.env.REACT_APP_TRACKING_GRAPHQL_CLIENT,
    // }),
  ]),
  cache: new InMemoryCache(),
})

ReactDOM.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <Switch>
        <Redirect from="/index" to="/" />
        <Redirect from="/logout" to="/" />
        <Route path="/leaderboard/:province/:amphoe" component={District} />
        <Route path="/leaderboard/:province" component={Amphoe} />
        <Route path="/individual-result/:registrationId" component={IndividualResults} />
        <Route path="/individual-search/:slug" component={IndividualSearch} />
        <Route path="/department" component={DepartCount} />
        <Route path="/leaderboard" component={LeaderBoard} />
        <AuthProvider>
          <Route path="/" component={App} />
          {/* <Route path="/new-path">
            <Place />
          </Route> */}
        </AuthProvider>
      </Switch>

    </ApolloProvider>
  </BrowserRouter>,
  document.getElementById('root'),
)

// ReactDOM.render(

//   <ApolloProvider client={client}>

//     <BrowserRouter>

//       <Switch>
//         {/* <Route path="/report" component={Report} />
//         <Route path="/activity" component={Activity} />
//         <Route path="/province" component={Province} />
//         <Route path="/provincedetail" component={ProvinceDetail} /> */}
//         <Route path="/leaderboard/:province/:amphoe" component={District} />
//         <Route path="/leaderboard/:province" component={Amphoe} />
//         <Route path="/department" component={DepartCount} />
//         <Route path="/leaderboard" component={LeaderBoard} />
//         {/* <Route path="/provinceboard" component={ProvinceBoard} />
//         <Route path="/individual" component={IndividualReport} />
//         <Route path="/individualboard" component={IndividualLeader} />
//         <Route path="/individuallead" component={IndividualBoard} />
//         <Route path="/departmentboard" component={DepartmentBoard} />
//         <Route path="/individualreport" component={IndividualReportSelect} />
//         <Route path="/departmentselect" component={DepartmentSelect} />
//         <Route path="/departboard" component={DepartBoard} />
//         <Route path="/genderreport" component={GenderReport} />
//         <Route path="/malereport" component={MaleReport} />
//         <Route path="/femalereport" component={FemaleReport} />

//         <Route path="/" component={Index} /> */}
//         <Route path="/" component={PrivatePage} />

//       </Switch>

//     </BrowserRouter>

//   </ApolloProvider>,

//   document.getElementById('root'))

// If you want your app to work offline and load faster, you can change

// unregister() to register() below. Note this comes with some pitfalls.

// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister()

