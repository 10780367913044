import React, { Fragment, useEffect, useState } from 'react'
import { graphql, useQuery } from 'react-apollo'
import {
  Button, Row, Col,
  Layout, Table,
} from 'antd'
import { Link } from 'react-router-dom'

import reportUserTypeCount from '../graphql/queries/reportCountUserType'

import ProvinceReport from './ProvinceReport'

const {
  Header, Footer, Sider, Content,
} = Layout

const columns = [
  {
    title: 'ประเภทผู้สมัคร',
    dataIndex: '_id',
    render: record => (record === 'บุคคลในหน่วยงาน' ? <Link to="/department">{record}</Link> : record),
  },
  {
    title: 'จำนวนผู้สมัคร (คน)',
    dataIndex: 'count',
    key: '',
    defaultSortOrder: 'descend',
    sorter: (a, b) => (a.isTotal || b.isTotal ? 0 : (a.count - b.count)),
    render: record => (`${record.toLocaleString() } `),

  },
  {
    title: 'ระยะวิ่งสะสม (กม.)',
    dataIndex: 'distance',
    key: '',
    render: record => (`${record.toLocaleString(undefined, { minimumFractionDigits: 2 }) } `),
  },
]

const LeaderBoard = (props) => {
  // const {
  //   data = { reportCountProvince: [] }, loading, error,
  // } = useQuery(reportProvinceCount, {
  //   variables: { slug: 'nscth' },
  // })
  const {
    data = { reportCountUserType: [] }, loading,
  } = useQuery(reportUserTypeCount, {
    variables: { slug: 'nscth' },
  })
  const locale = {
    emptyText: 'fetching data',
  }

  let reportSumAll = {
    _id: 'รวม',
    count: 0,
    distance: 0,
  }
  if (!loading) {
    const total = data.reportCountUserType.reduce((totalRecord, record) => ({
      count: totalRecord.count + record.count,
      distance: totalRecord.distance + record.distance,
    }), {
      count: 0,
      distance: 0,
    }) // { count, distance }
    // console.log({ total })
    reportSumAll = {
      _id: 'รวม', count: total.count, distance: total.distance, isTotal: true,
    }
  }
  const reportCountAll = [...data.reportCountUserType, reportSumAll]

  return (
    <Layout>
      {/* <Header style={{ backgroundImage: 'url(../Header.jpg)', zIndex: 2,  }}><img style={{ width: '50px', marginLeft: '-30px', marginTop: '-2px' }} src="../1200px-Seal_of_the_Ministry_of_Public_Health_of_Thailand.svg.png" alt="logo" /></Header> */}
      <img src="../Header.jpg" style={{ zIndex: 2, width: '100%', height: '50px' }} />
      <img
        style={{
          width: '40px', position: 'absolute', zIndex: 3, marginLeft: '5px', marginTop: '5px',
        }}
        src="../1200px-Seal_of_the_Ministry_of_Public_Health_of_Thailand.svg.png"
        alt="logo"
      />
      <Content style={{ backgroundColor: '#A9BFBB' }}>
        <img
          src="../logo-k.png"
          style={{
            width: '150px',
            position: 'relative',
            zIndex: 2,
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'flex',
            marginTop: '20px',
          }}
          alt=""
        />
        <img src="../bg.png" style={{ width: '100%', position: 'absolute', marginTop: '-235px' }} />
        <div>
          <Table
          // rowSelection={rowSelection}
            dataSource={reportCountAll}
            columns={columns}
            locale={locale}
            rowKey="userType"
            pagination={false}
            size="small"
          // onChange={onTableChange}
            loading={loading}
            bordered
          />
          <br />
          {/* <Table
          // rowSelection={rowSelection}
            dataSource={data.reportCountProvince}
            columns={columns2}
            rowKey="province"
            pagination={{ pageSize: 77 }}
            size="small"
          // onChange={onTableChange}
            loading={loading}
            bordered
          /> */}
          {loading ? <p>loading</p> : <ProvinceReport />}

        </div>

      </Content>
    </Layout>
  )
}
export default LeaderBoard
