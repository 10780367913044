import React, { Fragment, useEffect, useState } from 'react'
import { graphql, useQuery } from 'react-apollo'
import {
  Button, Row, Col,
  Layout, Table,
} from 'antd'
import { Link } from 'react-router-dom'
import _ from 'lodash'

import department from '../data/department.json'
import RegistrationDepartCount from '../graphql/queries/reportCountDepartment'

const {
  Header, Footer, Sider, Content,
} = Layout

const columns = [
  {
    title: 'หน่วยงาน',
    dataIndex: 'department',
    key: 'department',
    align: 'center',
    className: 'headercolor',
  },
  {
    title: 'ยอดผู้สมัคร (คน)',
    dataIndex: 'count',
    key: 'count',
    align: 'center',
    className: 'headercolor',
    defaultSortOrder: 'descend',
    sorter: (a, b) => (a.isTotal || b.isTotal ? 0 : (a.count - b.count)),
    render: record => (`${record.toLocaleString() }`),
  },
  {
    title: 'ระยะวิ่งสะสม (กม.)',
    dataIndex: 'distance',
    key: '',
    align: 'center',
    className: 'headercolor',
    sorter: (a, b) => (a.isTotal || b.isTotal ? 0 : (a.distance - b.distance)),
    render: record => (`${record.toLocaleString(undefined, { minimumFractionDigits: 2 }) }`),
  },
]

const DepartmentCount = (props) => {
  const {
    data = { reportCountDepartment: [] }, loading, error, fetchMore,
  } = useQuery(RegistrationDepartCount, {
    variables: { slug: 'nscth', department: '' },
  })
  // if (loading && loadstatus) {
  //   return <p>loading...</p>
  // }

  let reportSumDepartment = {
    department: 'รวม',
    count: 0,
    distance: 0,
  }
  if (!loading) {
    data.reportCountDepartment = data.reportCountDepartment.filter(item => item.department != null)
    const total = data.reportCountDepartment.reduce((totalRecord, record) => ({
      count: totalRecord.count + record.count,
      distance: totalRecord.distance + record.distance,
    }), {
      count: 0,
      distance: 0,
    }) // { count, distance }
    console.log({ total })
    reportSumDepartment = {
      department: 'รวม', count: total.count, distance: total.distance, isTotal: true,
    }
  }

  const reportSumAllDepartment = [...data.reportCountDepartment, reportSumDepartment]

  return (

    <Table
              // rowSelection={rowSelection}
      dataSource={reportSumAllDepartment}
      columns={columns}
      rowKey="department"
      pagination={false}

            // onChange={onTableChange}
      loading={loading}

      bordered
    />

  )
}
export default DepartmentCount
