import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Switch, withRouter, Link,
} from 'react-router-dom'
import {
  Menu, Icon, Layout, Button, PageHeader,
} from 'antd'

import { compose } from 'recompose'

import PrivateRoute from '../contexts/PrivateRoute'
import AuthContext from '../contexts/Auth'

import firebase from '../firebase'

import Report from './report'
import IndividualReportSelect from './individualReportSelect'
import IndividualReport from './individualReport'
import GenderReport from './genderReport'
import MaleReport from './maleReport'
import FemaleReport from './femaleReport'
import LeaderBoard from './leaderboard'
import IndividualBoard from './individualBoard'
import DepartmentSelect from './departmentSelect'
import DepartmentBoard from './departmentBoard'
import DepartBoard from './departBoard'
import Province from './provinces'
import ProvinceBoard from './provinceBoard'
import Provincedetail from './provinceDetail'
import SelectBoard from './selectBoard'
// import DepartmentReport from './departmentReport'
import LeaderboardCount from './nolayoutLeaderBoard'
import DepartmentReport from './departmentCount'
import NolaoutAmphoeCount from './nolayoutAmphoeCount'
import NolaoutDistrictCount from './nolayoutDistrictCount'
import NolaoutDepartmentCount from './nolayoutDepartmentCount'
import NolaoutProvinceCount from './nolayoutProvinceReport'
import ExportDistrict from './exportDistrict'
import ExportAllUser from './exportAllUser'
import ExportDistance from './exportDistance'
import IndividualSearch from './SearchIndividual'
import IndividualResult from './ResultIndividual'
import ExportAmphoe from './exportAmphoe'
import ExportMostProgressDistance from './exportMostDistance'

import Index from './index'

const {
  Header, Content, Sider, Footer,
} = Layout
const { SubMenu } = Menu

const PrivatePage = (props) => {
  const { location: { pathname } } = props
  const { currentUser } = useContext(AuthContext)
  // if (!firebase.getCurrentUsername()) {
  //   // not logged in
  //   props.history.replace('/login')
  //   return null
  // }
  // useEffect(() => {
  //   const dd = firebase.getCurrentUsername()
  // }, {})
  const handleTitle = (pathsname) => {
    if (pathsname === '/') {
      return (
        <h1 style={{
          color: '#27AA9C', padding: '5px', paddingTop: '12px', fontWeight: 'bold',
        }}
        >Home
        </h1>
      )
    } if (pathsname === '/report' || pathsname === '/individual' || pathsname === '/districtreport' || pathsname === '/distancereport' || pathname === '/mostdistancereport') {
      return (
        <h1 style={{
          color: '#27AA9C', padding: '5px', paddingTop: '12px', fontWeight: 'bold',
        }}
        >Report
        </h1>
      )
    } if (pathsname === '/individual-searchs/nscth' || pathsname.replace(/^\/([^\/]*).*$/, '$1') === 'individual-results') {
      return (
        <h1 style={{
          color: '#27AA9C', padding: '5px', paddingTop: '12px', fontWeight: 'bold',
        }}
        >จัดการข้อมูลสมากชิก
        </h1>
      )
    }
    return (
      <h1 style={{
        color: '#27AA9C', padding: '5px', paddingTop: '12px', fontWeight: 'bold',
      }}
      >Leader Board
      </h1>
    )
  }
  return (
    <Layout style={{ fontFamily: 'DB', height: '100vh' }}>
      {/* <div style={{
        position: 'absolute', marginTop: '100px', display: 'inline-block', zIndex: 999,
      }}
      > */}
      <Sider
        style={{ position: 'relative' }}
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          console.log(broken)
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type)
        }}
      >
        <div className="logo"><Link to="/"><img style={{ width: '90px', margin: '10px', marginLeft: '2px' }} className="ant-menu-item" src="../Seal_of_the_Ministry_of_Public_Health_of_Thailand.svg.png" alt="logo" /></Link></div>
        <Menu mode="inline">
          {/* <Menu.Item key="1">
            <Link to="/">
              <Icon type="user" />
              <span className="nav-text">หน้าแรก</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="2">
            <Link to="/province">
              <Icon type="video-camera" />
              <span className="nav-text">จัดการข้อมูลจังหวัด</span>
            </Link>
          </Menu.Item> */}
          <Menu.Item key="1">
            <Link to="/">
              <i aria-label="icon: user"><img
                src="../home-run.png"
                style={{
                  width: '14px', height: '14px', marginRight: '18px', marginTop: '-5px',
                }}
              />
              </i>
              <span className="nav-text" style={{ fontSize: '20px' }}>Home</span>
            </Link>
          </Menu.Item>
          {/* <Menu.Item key="3">
            <Link to="/leaderboards">
              <Icon type="upload" />
              <span className="nav-text">Leader Board</span>
            </Link>
          </Menu.Item> */}
          {currentUser.displayName === 'admin' ? (
            <Menu.Item key="2">
              <Link to="/individual-searchs/nscth">
                <i aria-label="icon: user"><img
                  src="../leadership_menu.png"
                  style={{
                    width: '14px', height: '14px', marginRight: '18px', marginTop: '-5px',
                  }}
                />
                </i>
                <span className="nav-text" style={{ fontSize: '20px' }}>จัดการข้อมูลสมาชิก</span>
              </Link>
            </Menu.Item>
          ) : null}
          {/* <Menu.Item key="2">
            <Link to="/individual-searchs/nscth">
              <i aria-label="icon: user"><img src="../leadership_menu.png" style={{width:"14px", height:"14px", marginRight:'18px', marginTop:"-5px"}}></img></i>
              <span className="nav-text" style={{fontSize:"20px"}}>จัดการข้อมูลสมาชิก</span>
            </Link>
          </Menu.Item> */}
          <SubMenu
            style={{ paddingLeft: '5px' }}
            key="3"
            title={(
              //
              <span>
                <Link to="/leaderboards" style={{ color: '#fff' }}>
                  {/* <Icon type="setting" /> */}
                  <i aria-label="icon: user"><img
                    src="../news-menu.png"
                    style={{
                      width: '14px', height: '14px', marginRight: '18px', marginTop: '-5px',
                    }}
                  />
                  </i>
                  <span style={{ fontSize: '20px' }}>Leader Board</span>
                </Link>

              </span>

              // </Link>
          )}
          >
            <Menu.Item key="9">
              <Link to="/provincesboard">
                <span style={{ fontSize: '18px' }} className="nav-text">จังหวัด</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="10">
              <Link to="/departmentboard">
                <span style={{ fontSize: '18px' }} className="nav-text">หน่วยงาน</span>
              </Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu
            style={{ paddingLeft: '5px' }}
            title={(
              <span>
                <i aria-label="icon: user"><img
                  src="../report-menu.png"
                  style={{
                    width: '14px', height: '14px', marginRight: '18px', marginTop: '-5px',
                  }}
                />
                </i>
                <span style={{ fontSize: '20px' }}>Report</span>
              </span>
        )}
          >
            <Menu.Item key="11">
              <Link to="/individual">
                <span style={{ fontSize: '18px' }} className="nav-text">บุคคล</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="12">
              <Link to="/districtreport">
                <span style={{ fontSize: '18px' }} className="nav-text">จังหวัด</span>
              </Link>
            </Menu.Item>
            {/* <Menu.Item key="13">
              <Link to="/amphoereport">
                <span style={{ fontSize: '18px' }} className="nav-text">อำเภอ</span>
              </Link>
            </Menu.Item> */}
            <Menu.Item key="14">
              <Link to="/distancereport">
                <span style={{ fontSize: '18px' }} className="nav-text">ระยะทาง</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="15">
              <Link to="/mostdistancereport">
                <span style={{ fontSize: '18px' }} className="nav-text">ระยะทางสะสม 10 อันดับ</span>
              </Link>
            </Menu.Item>
          </SubMenu>

          {/* <Menu.Item key="4">
            <Link to="/report">
              <i aria-label="icon: user"><img src="../report-menu.png" style={{width:"14px", height:"14px", marginRight:'18px', marginTop:"-5px"}}></img></i>
              <span style={{fontSize:"20px"}} className="nav-text">Report</span>
            </Link>
          </Menu.Item> */}
        </Menu>
      </Sider>
      {/* </div> */}

      <Layout>
        <Header style={{}}>
          {/* <div className="logo" />
          <Menu
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={['']}
            style={{ lineHeight: '64px' }}
          > */}
          {/* <Menu.Item to="/" key="1"><img style={{ width: '100px' }} className="ant-menu-item" src="../Logo_customer1.png" alt="logo" /></Menu.Item> */}
          {/* <Menu.Item style={{ float: 'right' }} key="3"><Button type="primary" onClick={async () => { await firebase.logout(); props.history.push('/logout') }}><Icon style={{ marginRight: 'auto', marginLeft: 'auto' }} type="logout" /></Button></Menu.Item> */}
          {/* <Menu.Item style={{ float: 'right' }} key="2">{user.name}</Menu.Item> */}

          {/* </Menu> */}
          <PageHeader
            style={{
              border: '1px solid rgb(235, 237, 240)',
              background: '#fff',
              borderRadius: '20px',
              margin: '10px',
              padding: '0px 15px',
              color: '#27AA9C',
              justifyContent: 'flex-start',
            }}

            title={handleTitle(props.location.pathname)}
            // subTitle="This is a subtitle"
          />
          {/* <Avatar size={45} icon="user" style={{ zIndex: 2, position: 'absolute', marginTop: '-50px' , marginLeft:'800px'}} /> */}
          <Button
            style={{
              zIndex: 2, float: 'right', position: 'relative', marginTop: '-55px', marginRight: '50px', backgroundColor: '#27AA9C', borderColor: '#27AA9C',
            }}
            type="primary"
            onClick={async () => { await firebase.logout(); props.history.push('/logout') }}
          ><Icon style={{ marginRight: 'auto', marginLeft: 'auto' }} type="logout" />
          </Button>
        </Header>
        <Content style={props.location.pathname === '/provincesboard' ? { margin: '24px 16px 0', height: '88vh' } : { margin: '24px 16px 0', height: '79vh' }}>
          <div style={{ minHeight: 360 }}>
            {/* <Button onClick={(e) => firebase.register('admin', 'admin@vr.report', '123456', 'Admin')}>register</Button> */}
            <Switch>
              <PrivateRoute exact path="/" component={Index} />
              <PrivateRoute path="/individualreport" component={IndividualReportSelect} />
              <PrivateRoute path="/report" component={Report} />
              <PrivateRoute path="/individual" component={ExportAllUser} />
              <PrivateRoute path="/genderreport" component={GenderReport} />
              <PrivateRoute path="/malereport" component={MaleReport} />
              <PrivateRoute path="/femalereport" component={FemaleReport} />
              {/* <PrivateRoute path="/leaderboards" component={SelectBoard} /> */}
              <PrivateRoute path="/individuallead" component={IndividualBoard} />
              <PrivateRoute path="/departmentselect" component={DepartmentSelect} />
              {/* <PrivateRoute path="/departmentboard" component={DepartmentBoard} /> */}
              <PrivateRoute path="/departboard" component={DepartBoard} />
              <PrivateRoute path="/province" component={Province} />
              <PrivateRoute path="/provinceboard" component={ProvinceBoard} />
              <PrivateRoute path="/provincedetail" component={Provincedetail} />
              <PrivateRoute path="/provincesboard/:province/:amphoe" component={NolaoutDistrictCount} />
              <PrivateRoute path="/provincesboard/:province" component={NolaoutAmphoeCount} />
              <PrivateRoute path="/provincesboard/" component={NolaoutProvinceCount} />
              <PrivateRoute path="/leaderboards" component={LeaderboardCount} />
              <PrivateRoute path="/departmentboard" component={NolaoutDepartmentCount} />
              <PrivateRoute path="/districtreport" component={ExportDistrict} />
              <PrivateRoute path="/distancereport" component={ExportDistance} />
              <PrivateRoute path="/amphoereport" component={ExportAmphoe} />
              <PrivateRoute path="/individual-searchs/:slug" component={IndividualSearch} />
              <PrivateRoute path="/individual-results/:registrationId" component={IndividualResult} />
              <PrivateRoute path="/mostdistancereport" component={ExportMostProgressDistance} />
            </Switch>
          </div>
          {props.location.pathname === '/provincesboard' ? (
            <Footer style={{
              textAlign: 'left', fontSize: '18px', marginLeft: '-30px', fontWeight: '400', color: 'rgb(39, 170, 156)',
            }}
            >Powered by Thai.run
            </Footer>
          ) : null}

        </Content>
        {props.location.pathname !== '/provincesboard' ? (
          <Footer style={{
            textAlign: 'left', fontSize: '18px', marginLeft: '-30px', fontWeight: '400', color: 'rgb(39, 170, 156)',
          }}
          >Powered by Thai.run
          </Footer>
        ) : null}
      </Layout>
    </Layout>
  )
}
PrivatePage.propTypes = {
  location: PropTypes.shape().isRequired,
}

export default compose(
  withRouter,
)(PrivatePage)
