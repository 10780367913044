import React, { useState, useEffect } from 'react'
import { useMutation } from 'react-apollo'
import { Button, Modal } from 'antd'

// import moment from 'moment'


// import exportAllUserStatsMutation from '../graphql/mutations/exportAllUser'
import startDownloadMutation from '../graphql/mutations/startDownload'
import recentDownloadQuery from '../graphql/queries/recentDownload'
// import recentDownloadQuery from '../graphql/queries/recentDownload'

import CurrentDownload from '../component/CurrentDownload'

import IndividualReport from './individualReport'


const ExportAllUser = (props) => {
  // const [exportAllUserStats, { data, loading }] = useMutation(exportAllUserStatsMutation)
  const [startDownload, { data, loading }] = useMutation(startDownloadMutation, {
    variables: { slug: 'nscth', resource: 'user-stats' }
  })

  // console.log(urlDownload)
  // console.log({ recentDownloadData })
  return (
    <div>
      <div style={{ textAlign: 'right', marginBottom: '10px' }}>
        <CurrentDownload resource="user-stats" slug="nscth" />
        {' '}
        <Button
        // style={{
          //   marginRight: 0, marginLeft: 'auto', display: 'block', marginBottom: '10px',
          // }}
          onClick={async (e) => {
            e.preventDefault()
            try {
              // await exportAllUserStats({ variables: { slug: 'nscth' } })
              // showModal()
              await startDownload()
            } catch (errer) {
              // console.log(errer)
              alert('โปรดลองใหม่ภายหลัง')
            }
          }}
          loading={loading}
        >Export
        </Button>
      </div>
      <IndividualReport />
    </div>
  )
}
export default ExportAllUser
