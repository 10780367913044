import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { graphql, useMutation } from 'react-apollo'
import { Button, Modal, Icon } from 'antd'

import exportDistrictStatsMutation from '../graphql/mutations/exportDistrict'

import ProvinceReport from './reportNolayoutDistrict'

const ExportDistrict = (props) => {
  const [visible, setVisible] = useState(false)
  const [status, setStatus] = useState(true)
  const [urlDownload, setUrlDownload] = useState({})
  const [exportDistrictStats, { data, loading }] = useMutation(exportDistrictStatsMutation)
  const showModal = () => {
    setVisible(true)
  }

  const handleOk = (e) => {
    setVisible(false)
  }

  const handleCancel = (e) => {
    setVisible(false)
  }
  if (visible && !loading && status) {
    setUrlDownload(data)

    setStatus(false)
  }
  console.log(urlDownload)
  return (
    <div>
      <Button
        style={{
          marginRight: 0,
          marginLeft: 'auto',
          display: 'block',
          marginBottom: '10px',
          backgroundColor: '#27AA9C',
          borderColor: '#27AA9C',
          color: '#fff',
          fontWeight: 'bold',
          fontSize: '16px',
        }}
        onClick={async (e) => {
          e.preventDefault()
          await exportDistrictStats({ variables: { slug: 'nscth' } })
          showModal()
        }}
        loading={loading}
      ><Icon type="file" /> Export
      </Button>
      <ProvinceReport />
      <Modal
        title="Download District Report"
        visible={visible}
        // onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button onClick={handleCancel}>
            Close
          </Button>,
        ]}
      >

        {visible && !loading && !status ? <a href={`${urlDownload.exportDistrictStats.downloadUrl}`} target="_blank" download>Download</a> : 'export error'}

      </Modal>
    </div>
  )
}
export default ExportDistrict
