import gql from 'graphql-tag'

export default gql`
query getRegistration($slug: String, $skip: Int) {
  registrationMany(filter: {
    slug : $slug
    
  },limit:1000, skip: $skip) {
    _id
    bib
    profile {
      name
      lastName
      province
      gender
      amphoe
      district
    }
    totalDistance
    results {
      userId
      results
      totalDistance
    }
  }
}
`
