import React, { useContext, useState, useEffect } from 'react'

import PrivatePage from './pages/PrivatePage'
import LoginPage from './pages/LoginPage'
import AuthContext from './contexts/Auth'
import firebase from './firebase'

const App = () => {
  const { currentUser } = useContext(AuthContext)
  const [firebaseInitialized, setFirebaseInitialized] = useState(false)

  useEffect(() => {
    firebase.isInitialized().then((val) => {
      setFirebaseInitialized(val)
    })
  })
  if (firebaseInitialized === false) { return <p>loading</p> }
  if (currentUser) {
    return (<PrivatePage />)
  }

  return (<LoginPage />)
}

export default App

