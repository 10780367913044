import React, { useState, Fragment } from 'react'
import {
  Layout, Input, Select, Button, Table, Form, Icon, Modal, Tooltip, message,
} from 'antd'
import gql from 'graphql-tag'
import { graphql, useQuery, useMutation } from 'react-apollo'
import { Link } from 'react-router-dom'

const listTypeSearch = [
  {
    key: 'registrationId',
    value: 'Registration ID',
  }, {
    key: 'name',
    value: 'Name',
  }, {
    key: 'idCard',
    value: 'ID Card',
  }, {
    key: 'bib',
    value: 'Bib Number',
  }, {
    key: 'phone',
    value: 'Phone',
  },
]

const querySearchRegistrations = gql`query searchRegistration($slug: String!, $typeSearch: String!, $text: String) {
  searchRegistrations(slug: $slug, typeSearch: $typeSearch, text: $text) {
    _id
    userId
    slug
    date
    status
    bib
    vrSubmissions {
      bib
      slug
      registrationId
      distance
      progressDistance
      createdAt
      imageRef
    }
    profile {
      name
      lastName
      email
      phone
      birthDate
      display
      idCard
      height
      weight
      houseNo
      villageNo
      village
      lane
      road
      zipCode
      gender
      shirtSize
      province
      amphoe
      district
      userType
      department
    }
  }
}`

const mutationRemoveRegistration = gql`mutation removeRegistration($registrationId: String!) {
  removeRegistration(registrationId: $registrationId)
}`

const mutationEditIdCard = gql`mutation editIdCard($registrationId: String!, $idCard: String!) {
  editIdCard(registrationId: $registrationId, idCard: $idCard) {
    _id
  }
}`

function IndividualSearch({ match: { params } }) {
  const [typeSearch, setTypeSearch] = useState('')
  const [text, setText] = useState('')
  const [isSearch, setIsSearch] = useState(false)

  const handleSearch = () => {
    console.log({ typeSearch, text, slug: params.slug })
  }
  // const isSearch = typeSearch !== "" && text !== ""
  return (
    <Fragment>
      {/* <h1>Individual search.</h1> */}
      <div>
        <div style={{
          boder: '1px',
          background: 'rgb(255, 255, 255)',
          padding: '10px',
          marginBottom: '20px',
          boxShadow: '0 30px 50px rgba(0,0,0,.1)',
          marginTop: '20px',
          display: 'flex',
        }}
        >
          <Form
            layout="inline"
            style={{ display: 'block', marginLeft: '10px' }}
          >
            <Form.Item
              style={{
                marginRight: '0px',
              }}
            >
              <Form.Item>
                <p className="labelform">Type Search</p>
              </Form.Item>
              <Form.Item>
                <Select
                  placeholder="Type Search"
                  style={{ width: 250, fontSize: '18px' }}
                  onChange={e => setTypeSearch(e)}
                >
                  {
                listTypeSearch.map(item => <Select.Option key={item} value={item.key}>{item.value}</Select.Option>)
              }
                </Select>
              </Form.Item>
            </Form.Item>
            {typeSearch !== '' ? (
              <Fragment>
                <Form.Item
                  style={{
                    marginRight: '0px',
                  }}
                >
                  <Form.Item>
                    <p className="labelform">{typeSearch}</p>
                  </Form.Item>
                  <Form.Item>
                    <Input
                      style={{ width: 250, fontSize: '18px' }}
                      placeholder={typeSearch}
                      onChange={(e) => {
                        const { value } = e.target
                        setText(value)
                        setIsSearch(false)
                      }}
                    />
                  </Form.Item>
                </Form.Item>
                <Form.Item>
                  <Button style={{ backgroundColor: '#27AA9C', borderColor: '#27AA9C' }} type="primary" onClick={() => setIsSearch(true)}><Icon type="search" /></Button>
                </Form.Item>
              </Fragment>
            ) : null}

          </Form>
        </div>
        {isSearch && (
        <div>
          <Registrations typeSearch={typeSearch} text={text} slug={params.slug} />
        </div>
        )}
      </div>
    </Fragment>
  )
}

function Registrations({ slug, typeSearch, text }) {
  const [idCard, setIdCard] = useState('')
  const [targetRegistrationId, setTargetRegistrationId] = useState('')
  const [isEditIdCard, setIsEditIdCard] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [editIdCard] = useMutation(mutationEditIdCard)
  const [removeRegistration] = useMutation(mutationRemoveRegistration)
  const { data, loading, refetch } = useQuery(querySearchRegistrations, {
    variables: {
      slug,
      typeSearch,
      text,
    },
  })
  const columns = [
    {
      title: 'RegistrationId',
      dataIndex: '_id',
      key: `_id_${Date.now()}`,
      render: (text, { _id }) => <Link to={`/individual-results/${_id}`}>{_id}</Link>,
      className: 'headercolor',
    }, {
      title: 'Bib Number',
      dataIndex: 'bib',
      key: 'bib',
      className: 'headercolor',
    }, {
      title: 'ID Card',
      dataIndex: 'profile.idCard',
      key: 'profile.idCard',
      className: 'headercolor',
    }, {
      title: 'Name',
      dataIndex: 'profile.name',
      key: 'profile.name',
      className: 'headercolor',
    }, {
      title: 'Submission',
      dataIndex: 'vrSubmissions',
      key: 'vrSubmissions',
      render: (i) => {
        const items = i.filter(e => e.distance)
        return `${items.length} time(s)`
      },
      className: 'headercolor',
    }, {
      title: 'Options',
      dataIndex: '_id',
      key: '_id',
      render: registrationId => (
        <div>
          <Tooltip title="แก้ไขเลขบัตรประชาชน">
            <Button
              type="primary"
              onClick={() => {
                setTargetRegistrationId(registrationId)
                setIsEditIdCard(true)
              }}
            >
              <Icon type="edit" />
            </Button>
          </Tooltip>
          {' '}
          <Tooltip title="ลบข้อมูลนักวิ่ง">
            <Button
              type="danger"
              onClick={() => {
                Modal.confirm({
                  title: 'คุณต้องการลบใช่ไหม ?',
                  onOk: async () => {
                    await removeRegistration({
                      variables: {
                        registrationId,
                      },
                    })
                    refetch()
                  },
                })
              }}
            >
              <Icon type="delete" />
            </Button>
          </Tooltip>
        </div>
      ),
      className: 'headercolor',
    },
  ]

  if (loading) return <Table loading />
  if (!data) return <Table hasData={null} />

  const { searchRegistrations } = data
  return (
    <div>
      <Table
        rowKey={({ _id }) => _id}
        columns={columns}
        dataSource={searchRegistrations}
      />
      <Modal
        title="แก้ไขเลขบัตรประชาชน"
        visible={isEditIdCard}
        onCancel={() => {
          setIdCard('')
          setTargetRegistrationId('')
          setIsEditIdCard(false)
        }}
        footer={[
          <Button
            onClick={() => {
              setIdCard('')
              setTargetRegistrationId('')
              setIsEditIdCard(false)
            }
            }
          >
            Cancel
          </Button>,
          <Button
            type="primary"
            disabled={!idCard}
            loading={isSaving}
            onClick={async () => {
              if (idCard.length < 13) {
                message.warning('เลขบัตรประชาชนไม่ครบ 13 หลัก')
                return
              }
              if (idCard.length > 13) {
                message.warning('เลขบัตรประชาชนเกิน 13 หลัก')
                return
              }
              setIsSaving(true)
              await editIdCard({
                variables: {
                  registrationId: targetRegistrationId,
                  idCard,
                },
              })
              refetch()
              setIdCard('')
              setTargetRegistrationId('')
              setIsSaving(false)
              setIsEditIdCard(false)
            }}
          >
              Save
          </Button>,
        ]}
      >
        <Input
          placeholder="ใส่เลขบัตรประชาชน"
          value={idCard}
          onChange={(e) => {
            const { value } = e.target
            if (isNaN(value)) {
              message.error('กรุณาใส่เป็นตัวเลข')
              return
            }
            setIdCard(value)
          }}
        />
      </Modal>
    </div>
  )
}

export default IndividualSearch
