import gql from 'graphql-tag'

export default gql`
query getRegistration($slug: String) {
  registrationMany(filter: {
    AND:[{slug: $slug},
      {profile : {
        gender:"ชาย"
      }}]
    
  },limit:200000) {
    _id
    userId
    bib
    profile {
      name
      lastName
      email
      phone
      birthDate
      display
      province
      idCard
      height
      weight
      gender
      amphoe
      district
      userType
      department
    }
    results {
      userId
      slug
      date
      results
    }
  }
}
`
