import { Button, Row, Col } from 'antd'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'

const IndividualReportSelect = (props) => {
  const history = useHistory()

  return (
    <Row>
      <Col span={8}>
        <Button size="large" onClick={() => { history.push('/individual') }}>บุคคล</Button>
      </Col>
      <Col span={8}>
        <Button size="large" onClick={() => { history.push('/genderreport') }}>เพศ</Button>
      </Col>
      <Col span={8}>
        {/* <Button size="large" onClick={() => { history.push('/individual') }}>อายุ</Button> */}
      </Col>
    </Row>

  )
}

export default IndividualReportSelect
