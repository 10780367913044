import gql from 'graphql-tag'

export default gql`
query getRegistration($slug: String,$province :String,$amphoe:String) {
  reportCountDistrict(slug:$slug,province:$province,amphoe:$amphoe) {
    distance
    count
    province
    amphoe
    district
  }

}
`
