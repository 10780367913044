import gql from 'graphql-tag'

export default gql`
query getRegistration($slug: String,$province :String) {
  reportCountAmphoe(slug:$slug,province:$province) {
    _id
    distance
    count
    province
    amphoe
  }
}
`
