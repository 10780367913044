import React, { Fragment, useEffect, useState } from 'react'
import { graphql, useQuery } from 'react-apollo'
import {
  Button, Row, Col,
  Layout, Table,
} from 'antd'

import reportDistrictCount from '../graphql/queries/reportCountDistrict'

const {
  Header, Footer, Sider, Content,
} = Layout

const columns = [
  {
    title: 'ตำบล',
    dataIndex: 'district',
  },
  {
    title: 'จำนวนผู้สมัคร (คน)',
    dataIndex: 'count',
    key: '',
    defaultSortOrder: 'descend',
    sorter: (a, b) => (a.isTotal || b.isTotal ? 0 : (a.count - b.count)),
    render: record => (`${record.toLocaleString() }`),
  },
  {
    title: 'ระยะวิ่งสะสม (กม.)',
    dataIndex: 'distance',
    key: '',
    render: record => (`${record.toLocaleString(undefined, { minimumFractionDigits: 2 }) }`),
  },
]

const AmphoeCount = (props) => {
  // const {
  //   data = { reportCountProvince: [] }, loading, error,
  // } = useQuery(reportProvinceCount, {
  //   variables: { slug: 'nscth' },
  // })
  const {
    data = { reportCountDistrict: [] }, loading,
  } = useQuery(reportDistrictCount, {
    variables: { slug: 'nscth', province: props.match.params.province, amphoe: props.match.params.amphoe },
  })
  const locale = {
    emptyText: 'fetching data',
  }
  let reportSumDistrict = {
    district: 'รวม',
    count: 0,
    distance: 0,
  }
  if (!loading) {
    const total = data.reportCountDistrict.reduce((totalRecord, record) => ({
      count: totalRecord.count + record.count,
      distance: totalRecord.distance + record.distance,
    }), {
      count: 0,
      distance: 0,
    }) // { count, distance }
    console.log({ total })
    reportSumDistrict = {
      district: 'รวม', count: total.count, distance: total.distance, isTotal: true,
    }
  }
  const reportSumAllDistrict = [...data.reportCountDistrict, reportSumDistrict]

  return (
    <Layout>
      {/* <Header style={{ backgroundImage: 'url(../Header.jpg)', zIndex: 2,  }}><img style={{ width: '50px', marginLeft: '-30px', marginTop: '-2px' }} src="../1200px-Seal_of_the_Ministry_of_Public_Health_of_Thailand.svg.png" alt="logo" /></Header> */}
      <img src={`${process.env.PUBLIC_URL } /Header.jpg`} style={{ zIndex: 2, width: '100%', height: '50px' }} />
      <img
        style={{
          width: '40px', position: 'absolute', zIndex: 3, marginLeft: '5px', marginTop: '5px',
        }}
        src={`${process.env.PUBLIC_URL } /1200px-Seal_of_the_Ministry_of_Public_Health_of_Thailand.svg.png`}
        alt="logo"
      />
      <Content style={{ backgroundColor: '#A9BFBB' }}>
        <img
          src={`${process.env.PUBLIC_URL }/logo-k.png`}
          style={{
            width: '150px',
            position: 'relative',
            zIndex: 2,
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'flex',
            marginTop: '20px',
          }}
          alt=""
        />
        <img src={`${process.env.PUBLIC_URL }/bg.png`} style={{ width: '100%', position: 'absolute', marginTop: '-235px' }} />
        <div>
          <Table
          // rowSelection={rowSelection}
            dataSource={reportSumAllDistrict}
            columns={columns}
            locale={locale}
            rowKey="amphoe"
            pagination={false}
            size="small"
          // onChange={onTableChange}
            loading={loading}
            bordered
          />

        </div>

      </Content>
    </Layout>
  )
}
export default AmphoeCount
