import React, { useState } from 'react'
import gql from 'graphql-tag'
import { graphql, useQuery, useMutation } from 'react-apollo'
import { Layout, Table, Button, Modal } from 'antd'
import moment from 'moment'
import 'moment/locale/th'
moment.locale('th')

function IndividualResults({ match: { params }}) {
  const [removeSubmission] = useMutation(mutationRemoveSubmission)
  const { data, loading, refetch } = useQuery(queryGetSubmission, {
    variables: {
      id: params.registrationId
    }
  })

  const columns = [
    {
      title: 'Image Ref.',
      dataIndex: 'imageRef',
      key: 'imageRef',
      render: (text) => text ? <img src={text} alt={text} width={50} /> : 'Image ref not found.',
      width: 200,
      ellipsis: true
    },
    {
      title: 'Submission distance',
      dataIndex: 'distance',
      key: 'distance',
      ellipsis: true
    },
    {
      title: 'Progress distance',
      dataIndex: 'progressDistance',
      key: 'progressDistance',
      ellipsis: true,
      render: text => text.toFixed(2)
    }, {
      title: 'Submission time',
      dataIndex: 'createdAt',
      key: 'createAt',
      ellipsis: true,
      render: text => moment(text).format('LLLL')
    }, {
      title: 'Options',
      render: ({ _id, registrationId}) => {
        return <Button type="danger" onClick={() => Modal.confirm({
          title: 'Do you want to delete last submission ?',
          onOk() {
            handleRemoveSubmission({ resultId: _id.toString(), registrationId: registrationId.toString(), removeSubmission, refetch})
          }
        })}>Remove</Button>
      }
    }
  ]

  const handleRemoveSubmission = async ({ registrationId, resultId, removeSubmission, refetch}) => {
    await removeSubmission({ variables: { 
      registrationId,
      resultId
    }})
    refetch()
  }

  if (loading) return <Table loading/>
  if (!data) return <div>Registration not found!</div>
  const { registrationByIds } = data
  const vrSubmissions = registrationByIds[0].vrSubmissions.filter(e => e.distance !== null)

  if (vrSubmissions.length === 0) return <Table hasData={null} />
  const lastIndex = vrSubmissions.length - 1
  const idLastSubmission = vrSubmissions[lastIndex]._id

  return <Layout>
    <Layout.Content>
      <h1>Individual results.</h1>
      <div style={{ textAlign: 'center' }}>
        <Table 
          rowKey={({_id}) => _id}
          dataSource={vrSubmissions} 
          columns={columns} 
          /* footer={() => <Button type="danger" onClick={() => Modal.confirm({
            title: 'Do you want to delete last submission ?',
            onOk() {
              handleRemoveSubmission({ idSubmission: idLastSubmission, removeSubmission, refetch})
            }
          })}>Remove last submission</Button> } */
        />
      </div>
    </Layout.Content>
  </Layout>
}

const mutationRemoveSubmission = gql`mutation removeSubmission($registrationId: String!, $resultId: String!) {
  removeSubmission(registrationId: $registrationId, resultId: $resultId)
}`

const queryGetSubmission = gql`query getSubmissionByRegId($id: [MongoID]!){
  registrationByIds(_ids: $id) {
    _id
    userId
    slug
    date
    status
    bib
    profile {
      name
      lastName
      email
      phone
      birthDate
      display
      idCard
      height
      weight
      houseNo
      villageNo
      village
      lane
      road
      zipCode
      gender
      shirtSize
      province
      amphoe
      district
      userType
      department
    }
    vrSubmissions {
      _id
      bib
      slug
      registrationId
      distance
      progressDistance
      createdAt
      imageRef
    }
  }
}`

export default IndividualResults



