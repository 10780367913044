import { Query, graphql, useQuery } from 'react-apollo'
import {
  Layout, Table, Button,
} from 'antd'
import PropTypes from 'prop-types'
import React, { useState, Fragment } from 'react'
// import { Link } from 'react-router-dom'
import { mod } from 'mathjs'
import XLSX from 'xlsx'
import moment from 'moment'

// import provinces from '../data/provinces.json'
import RegistrationQuery from '../graphql/queries/getRegistration'

const IndividualReport = (props) => {
  const [skip, setSkip] = useState(1000)
  const { data = { registrationMany: [] }, loading, fetchMore } = useQuery(RegistrationQuery, {
    variables: { slug: 'nscth', skip: 0 },
    fetchPolicy: 'cache-and-network',
  })

  // const { registrationMany: { loading, registrationMany = [] } } = props

  // const onSelectChange = (selectedRowKeys) => {
  //   setSelectedRowKeys(selectedRowKeys)
  //   setSelectedData(registrationMany.filter(regis => selectedRowKeys.includes(regis.bib)))
  //   console.log('selectedRowKeys changed: ', selectedRowKeys)
  //   console.log('datakey: ', selectedData)
  // }
  // const rowSelection = {
  //   selectedRowKey,
  //   onChange: onSelectChange,
  //   hideDefaultSelections: true,
  //   selections: [
  //     {
  //       key: 'all-data',
  //       text: 'Select All Data',
  //       onSelect: () => {
  //         setSelectedRowKeys(selectedData.filter(true).map(item => item.bib))
  //       },
  //     },
  //   ],
  // }
  const exportExcel = async () => {
    const excelData = data.registrationMany.map(items => ({
      bib: items.bib,
      'ชื่อ-สกุล': items.profile.lastName ? `${items.profile.name } ${ items.profile.lastName}` : items.profile.name,
      ตำบล: items.profile.district,
      อำเภอ: items.profile.amphoe,
      จังหวัด: items.profile.province,
      ระยะทางสะสม: items.results === null ? '0' : `${items.results.results.reduce((result, number) => result + number)}`,

    }))
    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(excelData)
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1')
    XLSX.writeFile(workbook, `รายงาน-รายบุคคล-${moment().format('YYYYMMDDHHmmSS')}.xlsx`)
  }
  // const exportCsv = async () => {
  //   const excelData = data.registrationMany.map(items => ({
  //     bib: items.bib,
  //     'ชื่อ-สกุล': items.profile.lastName ? `${items.profile.name } ${ items.profile.lastName}` : items.profile.name,
  //     ตำบล: items.profile.district,
  //     อำเภอ: items.profile.amphoe,
  //     จังหวัด: items.profile.province,
  //     ระยะทางสะสม: items.results === null ? '0' : `${items.results.results.reduce((result, number) => result + number)}`,

  //   }))
  //   const workbook = XLSX.utils.book_new()
  //   const worksheet = XLSX.utils.json_to_sheet(excelData)
  //   XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1')
  //   XLSX.writeFile(workbook, `รายงาน-รายบุคคล-${moment().format('YYYYMMDDHHmmSS')}.csv`)
  // }

  const columns = [
    {
      title: 'หมายเลขบิบ',
      dataIndex: 'bib',
      key: 'bib',
      align: 'center',
      className: 'headercolor',
    },
    {
      title: 'ชื่อ-สกุล',
      dataIndex: 'profile',
      key: 'name',
      align: 'center',
      className: 'headercolor',
      render: record => (record.lastName ? `${record.name } ${ record.lastName}` : record.name),
    },
    {
      title: 'ตำบล',
      dataIndex: 'profile.district',
      key: 'Subdistrict',
      align: 'center',
      className: 'headercolor',
    },
    {
      title: 'อำเภอ',
      dataIndex: 'profile.amphoe',
      key: 'district',
      align: 'center',
      className: 'headercolor',
    },
    {
      title: 'จังหวัด',
      dataIndex: 'profile.province',
      key: 'province',
      align: 'center',
      className: 'headercolor',
    },
    {
      title: 'ระยะทางสะสม',
      dataIndex: 'totalDistance',
      key: 'totalDistance',
      defaultSortOrder: 'descend',
      align: 'center',
      className: 'headercolor',
      render: (distance) => {
        return distance
          ? distance.toLocaleString(undefined, { minimumFractionDigits: 2 })
          : 0
      },
      // `${record.results.reduce((result, number) => result + number)}`
    },
  ]

  const onFetchMore = () => {
    fetchMore({
      query: RegistrationQuery,
      variables: { slug: 'nscth', skip },
      fetchPolicy: 'cache-and-network',
      updateQuery: (previousResult, { fetchMoreResult }) => ({
        ...previousResult,
        // Add the new matches data to the end of the old matches data.
        registrationMany: [
          ...previousResult.registrationMany,
          ...fetchMoreResult.registrationMany,
        ],
      }),
    })
  }
  const onTableChange = (pagination, filters, sorter, extra) => {
    // console.log('params', pagination, filters, sorter, extra, skip)
    if (mod(pagination.current, 100) === 0) {
      setSkip(skip + 1000)
      onFetchMore()
    }
    console.log(pagination.current)
    // onFetchMore()
  }
  return (
    <Fragment>
      {/* <h2>
              รายบุคคล
        <Button
          style={{ float: 'right', marginRight: '10px' }}
          type="primary"
          onClick={exportCsv}
          disabled={loading}
        >
                Export .Csv
        </Button>
        <Button
          type="primary"
          style={{ float: 'right', marginRight: '10px' }}
          onClick={exportExcel}
          disabled={loading}
        >
                Export Excel
        </Button>
      </h2> */}
      <Table
        // scroll={{ y: 'calc(100vh - 4em)' }}
              // rowSelection={rowSelection}
        dataSource={data.registrationMany}
        columns={columns}
        rowKey="_id"
        pagination={{ pageSize: 10 }}
        size="middle"

        onChange={onTableChange}
        loading={loading}
        bordered
      />
    </Fragment>

  )
}

IndividualReport.propTypes = {
  registrationMany: PropTypes.shape({
    error: PropTypes.object,
    loading: PropTypes.bool,
    registrationMany: PropTypes.array,
    vrResultsOne: PropTypes.array,
    refetch: PropTypes.func,
  }).isRequired,
}
IndividualReport.defaultProps = {}

export default IndividualReport
