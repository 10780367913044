import React, { Fragment, useEffect, useState } from 'react'
import { graphql, useQuery } from 'react-apollo'
import {
  Button, Row, Col,
  Layout, Table,
} from 'antd'
import { Link } from 'react-router-dom'
import _ from 'lodash'

import department from '../data/department.json'
import RegistrationDepartCount from '../graphql/queries/reportCountDepartment'

const {
  Header, Footer, Sider, Content,
} = Layout

const columns = [
  {
    title: 'หน่วยงาน',
    dataIndex: 'department',
    key: 'department',
  },
  {
    title: 'ยอดผู้สมัคร (คน)',
    dataIndex: 'count',
    key: 'count',
    defaultSortOrder: 'descend',
    sorter: (a, b) => (a.isTotal || b.isTotal ? 0 : (a.count - b.count)),
    render: record => (`${record.toLocaleString() }`),
  },
  {
    title: 'ระยะวิ่งสะสม (กม.)',
    dataIndex: 'distance',
    key: '',
    render: record => (`${record.toLocaleString(undefined, { minimumFractionDigits: 2 }) }`),
  },
]

const DepartmentCount = (props) => {
  const {
    data = { reportCountDepartment: [] }, loading, error, fetchMore,
  } = useQuery(RegistrationDepartCount, {
    variables: { slug: 'nscth', department: '' },
  })
  // if (loading && loadstatus) {
  //   return <p>loading...</p>
  // }

  let reportSumDepartment = {
    department: 'รวม',
    count: 0,
    distance: 0,
  }
  if (!loading) {
    data.reportCountDepartment = data.reportCountDepartment.filter(item => item.department != null)
    const total = data.reportCountDepartment.reduce((totalRecord, record) => ({
      count: totalRecord.count + record.count,
      distance: totalRecord.distance + record.distance,
    }), {
      count: 0,
      distance: 0,
    }) // { count, distance }
    console.log({ total })
    reportSumDepartment = {
      department: 'รวม', count: total.count, distance: total.distance, isTotal: true,
    }
  }

  const reportSumAllDepartment = [...data.reportCountDepartment, reportSumDepartment]

  return (
    <Layout>
      {/* <Header style={{ backgroundImage: 'url(../Header.jpg)', zIndex: 2,  }}><img style={{ width: '50px', marginLeft: '-30px', marginTop: '-2px' }} src="../1200px-Seal_of_the_Ministry_of_Public_Health_of_Thailand.svg.png" alt="logo" /></Header> */}
      <img src={`${process.env.PUBLIC_URL } /Header.jpg`} style={{ zIndex: 2, width: '100%', height: '50px' }} />
      <img
        style={{
          width: '40px', position: 'absolute', zIndex: 3, marginLeft: '5px', marginTop: '5px',
        }}
        src={`${process.env.PUBLIC_URL } /1200px-Seal_of_the_Ministry_of_Public_Health_of_Thailand.svg.png`}
        alt="logo"
      />
      <Content style={{ backgroundColor: '#A9BFBB' }}>
        <img
          src={`${process.env.PUBLIC_URL }/logo-k.png`}
          style={{
            width: '150px',
            position: 'relative',
            zIndex: 2,
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'flex',
            marginTop: '20px',
          }}
          alt=""
        />
        <img src={`${process.env.PUBLIC_URL }/bg.png`} style={{ width: '100%', position: 'absolute', marginTop: '-235px' }} />
        <div>
          <Table
              // rowSelection={rowSelection}
            dataSource={reportSumAllDepartment}
            columns={columns}
            rowKey="department"
            pagination={false}
            size="middle"

            // onChange={onTableChange}
            loading={loading}

            bordered
          />

        </div>

      </Content>
    </Layout>
  )
}
export default DepartmentCount
