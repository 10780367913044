import {
  Button, Row, Col,
} from 'antd'
import React, { Fragment } from 'react'
import { useHistory } from 'react-router-dom'

const Report = (props) => {
  const history = useHistory()

  return (
    <Fragment>
      <Row>
        <Col span={4}>
          <Button size="large" onClick={() => { history.push('/individual') }}>บุคคล</Button>
        </Col>
        <Col span={4}>
          <Button size="large" onClick={() => { history.push('/districtreport') }}>ตำบล</Button>
        </Col>
        <Col span={4}>
          <Button size="large" onClick={() => { history.push('/distancereport') }}>ระยะทาง</Button>
        </Col>
      </Row>
    </Fragment>

  )
}

export default Report
