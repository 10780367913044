import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { graphql, useMutation, useQuery } from 'react-apollo'
import {
  Button, Modal, Form, Table, Input, Icon, Select, Layout,
} from 'antd'

import provinces from '../data/provinces.json'

import ReportCountAmphoe from '../graphql/queries/reportCountAmphoe'
import ExportProgressDistance from '../graphql/mutations/exportProgressDistance'

const { Option } = Select
const { Header } = Layout

const ExportAmphoe = (props) => {
  const [visible, setVisible] = useState(true)
  const [status, setStatus] = useState(false)
  const [province, setProvince] = useState('')
  const [exportStatus, setExportStatus] = useState(false)

  const { data = { reportCountAmphoe: [] }, loading, refetch } = useQuery(ReportCountAmphoe, {
    variables: {
      slug: 'nscth', province: '',
    },
    fetchPolicy: 'cache-and-network',
  })

  const columns = [
    {
      title: 'อำเภอ',
      dataIndex: '_id',
      key: 'district',
      sorter: (a, b) => a._id.localeCompare(b._id),
      align: 'center',
      className: 'headercolor',
    },
    {
      title: 'จังหวัด',
      dataIndex: 'province',
      key: 'province',
      sorter: (a, b) => a.province.localeCompare(b.province),
      align: 'center',
      className: 'headercolor',
    },
    {
      title: 'จำนวนผู้สมัคร',
      dataIndex: 'count',
      key: 'province',
      sorter: (a, b) => (a.count - b.count),
      align: 'center',
      className: 'headercolor',
    },
    {
      title: 'ระยะสะสม',
      dataIndex: 'distance',
      key: 'distance',
      sorter: (a, b) => (a.distance - b.distance),
      render: record => record.toFixed(2),
      align: 'center',
      className: 'headercolor',
    },
  ]
  const showModal = () => {
    setVisible(true)
  }

  const handleOk = (e) => {
    setVisible(false)
  }

  const handleCancel = (e) => {
    setVisible(false)
    setExportStatus(false)
  }
  if (visible && !loading && status) {
    setVisible(false)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    try {
      refetch({
        slug: 'nscth', province,
      })
      setExportStatus(true)
      setStatus(true)
    } catch (err) {
      setExportStatus(false)
      setStatus(false)
    }
  }
  return (
    <div>
      <Table
        dataSource={data.reportCountAmphoe}
        columns={columns}
        rowKey="_id"
        size="middle"
        loading={loading}
      />

      <Modal
        title="Report - อำเภอ"
        visible={visible}
        // onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button
            type="primary"
            htmlType="submit"
            disabled={province === ''}
            loading={loading}
            onClick={e => handleSubmit(e)}
          >
            บันทึก
          </Button>,
          <Button onClick={handleCancel}>
            ยกเลิก
          </Button>,
        ]}
      >

        <div>
          <Form
            layout="inline"
            style={{ display: 'block', marginLeft: '10px' }}
          >
            <Form.Item
              style={{
                marginRight: '0px',
              }}
            >
              <Form.Item>
                <p className="labelform">จังหวัด</p>
              </Form.Item>
              <Form.Item>
                <Select
                  showSearch
                  placeholder="ค้นหาจังหวัด"
                  style={{ width: 250, fontSize: '18px' }}
                  onChange={value => (setProvince(value))}
                >
                  {provinces.map(item => <Option value={item.name.th}>{item.name.th}</Option>)}
                </Select>
              </Form.Item>
            </Form.Item>
            <Form.Item
              style={{
                marginRight: '0px',
              }}
            >
            </Form.Item>
            <Form.Item>
              {/* <Button
                style={{ backgroundColor: '#27AA9C', borderColor: '#27AA9C' }}
                htmlType="submit"
                disabled={province === ''}
                loading={loading}
                onClick={e => handleSubmit(e)}
              >
                <Icon type="search" style={{ color: '#fff', fontWeight: 'bold' }} />
              </Button> */}
            </Form.Item>
          </Form>
        </div>

      </Modal>
    </div>
  )
}
export default ExportAmphoe
