import { graphql } from 'react-apollo'
import {
  Layout, Table, Button,
} from 'antd'
import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import XLSX from 'xlsx'
import moment from 'moment'

import provinces from '../data/provinces.json'
import RegistrationMaleQuery from '../graphql/queries/getRegistrationMale'

const MaleReport = (props) => {
  const [selectedRowKey, setSelectedRowKeys] = useState([])
  const [selectedData, setSelectedData] = useState([])
  const { registrationMany: { loading, registrationMany = [] } } = props

  // const onSelectChange = (selectedRowKeys) => {
  //   setSelectedRowKeys(selectedRowKeys)
  //   setSelectedData(registrationMany.filter(regis => selectedRowKeys.includes(regis.bib)))
  //   console.log('selectedRowKeys changed: ', selectedRowKeys)
  //   console.log('datakey: ', selectedData)
  // }
  // const rowSelection = {
  //   selectedRowKey,
  //   onChange: onSelectChange,
  //   hideDefaultSelections: true,
  //   selections: [
  //     {
  //       key: 'all-data',
  //       text: 'Select All Data',
  //       onSelect: () => {
  //         setSelectedRowKeys(selectedData.filter(true).map(item => item.bib))
  //       },
  //     },
  //   ],
  // }
  const exportExcel = async () => {
    const excelData = registrationMany.map(items => ({
      bib: items.bib,
      'ชื่อ-สกุล': items.profile.lastName ? `${items.profile.name } ${ items.profile.lastName}` : items.profile.name,
      ตำบล: '',
      อำเภอ: '',
      จังหวัด: items.profile.province,
      ระยะทางสะสม: items.results === null ? '0' : `${items.results.results.reduce((result, number) => result + number)}`,

    }))
    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(excelData)
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1')
    XLSX.writeFile(workbook, `รายงาน-รายบุคคล-ชาย-${moment().format('YYYYMMDDHHmmSS')}.xlsx`)
  }
  const exportCsv = async () => {
    const excelData = registrationMany.map(items => ({
      bib: items.bib,
      'ชื่อ-สกุล': items.profile.lastName ? `${items.profile.name } ${ items.profile.lastName}` : items.profile.name,
      ตำบล: '',
      อำเภอ: '',
      จังหวัด: items.profile.province,
      ระยะทางสะสม: items.results === null ? '0' : `${items.results.results.reduce((result, number) => result + number)}`,

    }))
    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(excelData)
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1')
    XLSX.writeFile(workbook, `รายงาน-รายบุคคล-ชาย-${moment().format('YYYYMMDDHHmmSS')}.csv`)
  }

  const columns = [
    {
      title: 'หมายเลขบิบ',
      dataIndex: 'bib',
      key: 'bib',
    },
    {
      title: 'ชื่อ-สกุล',
      dataIndex: 'profile',
      key: 'name',
      render: record => (record.lastName ? `${record.name } ${ record.lastName}` : record.name),
    },
    {
      title: 'เพศ',
      dataIndex: 'profile.gender',
      key: 'gender',
    },
    {
      title: 'ตำบล',
      dataIndex: 'profile.district',
      key: 'Subdistrict',
    },
    {
      title: 'อำเภอ',
      dataIndex: 'profile.amphoe',
      key: 'district',
    },
    {
      title: 'จังหวัด',
      dataIndex: 'profile.province',
      key: 'province',
    },
    {
      title: 'ระยะทางสะสม',
      dataIndex: 'results',
      key: 'distance',
      defaultSortOrder: 'descend',
      render: record => (record === null ? '0' : `${record.results.reduce((result, number) => result + number)}`),
    },
  ]
  const onTableChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra)
  }

  return (
    <Fragment>
      <h2>
        <Button
          style={{ float: 'right', marginRight: '10px' }}
          type="primary"
          onClick={exportCsv}
          disabled={loading}
        >
                Export .Csv
        </Button>
        <Button
          type="primary"
          style={{ float: 'right', marginRight: '10px' }}
          onClick={exportExcel}
          disabled={loading}
        >
                Export Excel
        </Button>
      </h2>
      <Table
              // rowSelection={rowSelection}
        dataSource={registrationMany}
        columns={columns}
        rowKey="_id"
        pagination={{ pageSize: 10 }}
        size="middle"

        onChange={onTableChange}
        loading={loading}
        bordered
      />
    </Fragment>

  )
}

MaleReport.propTypes = {
  registrationMany: PropTypes.shape({
    error: PropTypes.object,
    loading: PropTypes.bool,
    registrationMany: PropTypes.array,
    vrResultsOne: PropTypes.array,
    refetch: PropTypes.func,
  }).isRequired,
}
MaleReport.defaultProps = {}

export default graphql(RegistrationMaleQuery, { name: 'registrationMany', options: { variables: { slug: 'nscth' } } })(MaleReport)
