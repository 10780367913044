import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import { Button, Row, Col } from 'antd'

const Index = props => (

  <Fragment>
    <Row style={{ marginTop: '30px' }}>
      <Col span={24}>
        <Row>
          {/* <Col xs={24} md={8} lg={8}>
            <Link to="/individual-searchs/nscth">
              <Button style={{ height: '150px', width: '200px', borderRadius: '15px' }}><img src="../leadership.png" style={{ width: '50px' }} /><br />
                <h1 style={{
                  color: '#27AA9C', paddingTop: '20px', fontWeight: '400', fontSize: '18px',
                }}
                >จัดการข้อมูลสมาชิก
                </h1>
              </Button>
            </Link>
          </Col> */}
          <Col xs={24} md={8} lg={8} style={{ paddingBottom: '20px' }}>
            <Link to="/leaderboards">
              <Button style={{ height: '150px', width: '200px', borderRadius: '15px' }}><img src="../news.png" style={{ width: '50px' }} /><br />
                <h1 style={{
                  color: '#27AA9C', paddingTop: '20px', fontWeight: '400', fontSize: '18px',
                }}
                >Leader Board
                </h1>
              </Button>
            </Link>
          </Col>
          <Col xs={24} md={8} lg={8}>
            <Link to="/individual">
              <Button style={{ height: '150px', width: '200px', borderRadius: '15px' }}><img src="../report.png" style={{ width: '50px' }} /><br />
                <h1 style={{
                  color: '#27AA9C', paddingTop: '20px', fontWeight: '400', fontSize: '18px',
                }}
                >Report
                </h1>
              </Button>
            </Link>
          </Col>
        </Row>
      </Col>
    </Row>
  </Fragment>

)

export default Index
