import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { graphql, useMutation, useQuery } from 'react-apollo'
import {
  Button, Modal, Form, Table, Input, Icon, Select, Layout, Checkbox,
} from 'antd'
import moment from 'moment'
import 'moment-timezone'

import ExportMostProgressDistance from '../graphql/mutations/exportMostProgressDistance'
import MostProgressDistance from '../graphql/queries/reportMostProgressDistance'

const { Option } = Select

const ExportMostDistance = (props) => {
  const [visible, setVisible] = useState(false)
  const [status, setStatus] = useState(true)
  const [urlDownload, setUrlDownload] = useState({})
  const [gender, setGender] = useState('ชาย')
  const [exportStatus, setExportStatus] = useState(true)
  const [exportMostProgressDistance, { data: exportdata, loading: loadingexport }] = useMutation(ExportMostProgressDistance)
  const { data = { progressDistanceMost: [] }, loading, refetch } = useQuery(MostProgressDistance, {
    variables: {
      slug: 'nscth', gender: 'ชาย', limit: 10,
    },
    fetchPolicy: 'cache-and-network',
  })

  const columns = [
    {
      title: 'วันที่',
      dataIndex: 'date',
      key: 'date',
      sorter: (a, b) => (moment(a.date) - moment(b.date)),
      align: 'center',
      className: 'headercolor',
      render: record => `${moment(record).tz('Asia/Bangkok').format('DD/MM/YYYY , HH:MM:SS')}`,
    },
    {
      title: 'หมายเลขบิบ',
      dataIndex: 'registration.bib',
      key: 'bib',
      sorter: (a, b) => (a.registration.bib - b.registration.bib),
      align: 'center',
      className: 'headercolor',
    },
    {
      title: 'ชื่อ-สกุล',
      dataIndex: 'registration.profile',
      key: 'name',
      sorter: (a, b) => a.registration.profile.name.localeCompare(b.registration.profile.name),
      render: record => (record.lastName ? `${record.name } ${ record.lastName}` : record.name),
      align: 'center',
      className: 'headercolor',
    },
    {
      title: 'เพศ',
      dataIndex: 'registration.profile.gender',
      key: 'gender',
      sorter: (a, b) => a.registration.profile.gender.localeCompare(b.registration.profile.gender),
      align: 'center',
      className: 'headercolor',
    },
    {
      title: 'ตำบล',
      dataIndex: 'registration.profile.district',
      key: 'Subdistrict',
      sorter: (a, b) => a.registration.profile.district.localeCompare(b.registration.profile.district),
      align: 'center',
      className: 'headercolor',
    },
    {
      title: 'อำเภอ',
      dataIndex: 'registration.profile.amphoe',
      key: 'district',
      sorter: (a, b) => a.registration.profile.amphoe.localeCompare(b.registration.profile.amphoe),
      align: 'center',
      className: 'headercolor',
    },
    {
      title: 'จังหวัด',
      dataIndex: 'registration.profile.province',
      key: 'province',
      sorter: (a, b) => a.registration.profile.province.localeCompare(b.registration.profile.province),
      align: 'center',
      className: 'headercolor',
    },
    {
      title: 'ระยะสะสม',
      dataIndex: 'progressDistance',
      key: 'registration',
      sorter: (a, b) => (a.progressDistance - b.progressDistance),
      render: record => record.toFixed(2),
      align: 'center',
      className: 'headercolor',
    },
  ]
  const showModal = () => {
    setVisible(true)
  }

  const handleOk = (e) => {
    setVisible(false)
  }

  const handleCancel = (e) => {
    setVisible(false)
    setExportStatus(false)
  }
  if (visible && !loading && status) {
    setUrlDownload(exportdata)

    setStatus(false)
  }
  // console.log(urlDownload)

  const handleSubmit = (e) => {
    e.preventDefault()
    refetch({
      slug: 'nscth', gender, limit: 10,
    })
    setExportStatus(true)
  }

  return (
    <div>
    <div style={{
      boder: '1px',
      background: 'rgb(255, 255, 255)',
      padding: '10px',
      marginBottom: '20px',
      boxShadow: '0 30px 50px rgba(0,0,0,.1)',
      marginTop: '20px',
      display: 'flex',
    }}
    >
      <div>
        <Form
          layout="inline"
          style={{ display: 'block', marginLeft: '10px' }}
          // onSubmit={e => handleSubmit(e)}
        >
          <Form.Item
            style={{
              marginRight: '0px',
            }}
          >
            <Form.Item>
              <p className="labelform">เพศ</p>
            </Form.Item>
            <Form.Item>
              <Select
                placeholder="ค้นหาเพศ"
                style={{ width: 250, fontSize: '18px' }}
                onChange={value => setGender(value)}
              >
                <Option value="ชาย">ชาย</Option>
                <Option value="หญิง">หญิง</Option>
              </Select>
            </Form.Item>
          </Form.Item>

          <Form.Item>
            <Button
              style={{ backgroundColor: '#27AA9C', borderColor: '#27AA9C' }}
              htmlType="submit"
              disabled={gender === ''}
              loading={loading}
              onClick={e => handleSubmit(e)}
            >
              <Icon type="search" style={{ color: '#fff', fontWeight: 'bold' }} />
            </Button>
          </Form.Item>
          <Form.Item>
            {exportStatus && !loading ? (
              <Button
                type="primary"
                style={{
                  backgroundColor: '#27AA9C',
                  borderColor: '#27AA9C',
                  color: '#fff',
                  fontWeight: 'bold',
                  fontSize: '16px',
                }}
                onClick={async (e) => {
                  e.preventDefault()
                  try {
                    await exportMostProgressDistance({
                      variables: {
                        slug: 'nscth', gender, limit: 10,
                      },
                    })
                    showModal()
                  } catch (err) {
                    console.log(err)
                  }
                }}
                loading={loadingexport}
              ><Icon type="file" />Export
              </Button>
            ) : (
              <Button
                disabled
                style={{
                  fontWeight: 'bold',
                  fontSize: '16px',
                }}
              ><Icon type="file" />
            Export
              </Button>
            )}
          </Form.Item>
        </Form>
      </div>

    </div>
    <Modal
      title="Download Report"
      visible={visible}
      onCancel={handleCancel}
      footer={[
        <Button onClick={handleCancel}>
          Close
        </Button>,
      ]}
    >

      <div style={{ textAlign: 'center' }}>
        {visible && !loadingexport && !status
          ? (
            <Button
              type="primary"
              size="large"
              style={{
                backgroundColor: '#27AA9C',
                borderColor: '#27AA9C',
              }}
            >
              <a href={`${urlDownload.exportMostProgressDistance.downloadUrl}`} target="_blank" download>
                <p style={{ color: '#fff', display: 'contents' }}><Icon type="download" />  Download</p>
              </a>
            </Button>
          )
          : 'export error'
        }
      </div>

    </Modal>

    <Table
      scroll={{ y: 'calc(100vh - 4em)' }}
      dataSource={data.progressDistanceMost}
      columns={columns}
      rowKey=""
      pagination={{ pageSize: 10 }}
      size="middle"
      loading={loading}
    />

  </div>
  )

}
export default ExportMostDistance
