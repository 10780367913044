import React, { useEffect, useState } from 'react'
import { Button, Spin } from 'antd'
import moment from 'moment'

import firebase from '../firebase'

// import currentDownloadQuery from '../graphql/queries/currentDownload'

const CurrentDownload = ({ resource, slug }) => {
  const [currentDownload, setCurrentDownload] = useState(null)
  useEffect(() => {
    const unsubscribe = firebase.db.collection(`downloads/${resource}/${slug}`)
      .orderBy('createdAt', 'desc')
      .limit(1).onSnapshot((snapshot) => {
        snapshot.docs.forEach((doc) => {
          const download = doc.data()
          setCurrentDownload(download)
        })
      })
    return unsubscribe
  }, [])

  // const { currentDownload } = data
  // console.log(currentDownload.status)
  // shouldRefresh.current = currentDownload.status !== 'completed'
  // console.log({ currentDownload })
  return (
    currentDownload && (
      <a href={currentDownload.status === 'completed' && currentDownload.downloadUrl} style={{ color: 'white' }}>
        <Button
          type={currentDownload.status === 'completed' ? 'primary' : 'default'}
          icon="download"
          // disabled={currentDownload.status !== 'completed'}
          loading={currentDownload.status !== 'completed'}
        >
          {moment(currentDownload.createdAt.toDate()).format('LLL')}
          {' '}
          {currentDownload.status === 'processing' && `( ${parseInt(currentDownload.progress)}% )`}
        </Button>
      </a>
    )
  )
}

export default CurrentDownload
