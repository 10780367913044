import {
  Button, Row, Col,
} from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

const DepartmentSelect = props => (

  <Row>
    <Col span={12}>
      <Link to="/departmentboard">
        <Button size="large">ภาพรวมกระทรวง</Button>
      </Link>
    </Col>
    <Col span={12}>
      <Link to="/departboard">
        <Button size="large">ภาพรวมหน่วยงาน</Button>
      </Link>
    </Col>
  </Row>

)
export default DepartmentSelect
