import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter, Redirect } from 'react-router-dom'
import { graphql, Mutation } from 'react-apollo'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { ApolloLink } from 'apollo-link'
import { compose } from 'recompose'

import {
  Form, Icon, Input, Button, Layout, Row, Col, Card, Avatar,
} from 'antd'

import AuthContext from '../contexts/Auth'
import loginMutation from '../graphql/mutations/login'

import firebase from '../firebase'

const { Content } = Layout

// const authMiddleware = new ApolloLink((operation, forward) => {
//   operation.setContext(({ headers = {} }) => {
//     const { token, user } = checkTokenExpired(localStorage.getItem('token'))
//     if (token && user) {
//       return {
//         headers: {
//           ...headers,
//           Authorization: `Bearer ${token}`,
//         },
//       }
//     }
//     return { headers }
//   })
//   return forward(operation)
// })
// const customClient = new ApolloClient({
//   uri: 'http://localhost:9000/graphql',
//   link: ApolloLink.from([
//     onError(({ graphQLErrors, networkError }) => {
//       if (graphQLErrors) {
//         console.error({ graphQLErrors })
//       }
//       if (networkError) {
//         console.error({ networkError })
//         // if (networkError.statusCode === 401) {
//         //   removeToken()
//         // }
//       }
//     }),
//     authMiddleware,
//     new HttpLink({
//       uri: 'http://localhost:9000/graphql',
//     }),
//     // new HttpLink({
//     //   uri: process.env.REACT_APP_TRACKING_GRAPHQL_CLIENT,
//     // }),
//   ]),
//   cache: new InMemoryCache(),
// })
const hasErrors = fieldsError => Object.keys(fieldsError).some(field => fieldsError[field])

const LoginPage = (props) => {
  const { history } = props
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [firebaseInitialized, setFirebaseInitialized] = useState(false)

  // const { setToken } = useContext(AuthContext)
  const {
    getFieldDecorator, getFieldsError, getFieldError, isFieldTouched,
  } = props.form
  const usernameError = isFieldTouched('email') && getFieldError('email')
  const passwordError = isFieldTouched('password') && getFieldError('password')

  // useEffect(() => {
  //   props.form.validateFields()
  // }, [])
  // const handleSubmit = async (e) => {
  //   e.preventDefault()

  //   // const { data: { login: token } } = await login({ variables: { email, password } })
  //   login({ variables: { email, password } })
  //   setToken(token)
  //   history.push('/')
  // }
  const { currentUser } = useContext(AuthContext)

  if (firebaseInitialized !== false) {
    return <Redirect to="/index" />
  }
  return (
    <Layout style={{ backgroundImage: `url(${process.env.PUBLIC_URL }/login_bg.jpg)`, fontFamily: 'DB', fontSize: '18px' }}>
      <Content>
        <Row type="flex" justify="center" align="middle" style={{ minHeight: '100vh' }}>
          <Col span={6} xs={12} md={6}>
            <Avatar
              size={64}
              icon="user"
              style={{
                zIndex: 2,
                position: 'absolute',
                left: '50%',
                transform: 'translate(-50%, -75%)',
                background: 'rgb(24, 151, 183)',
              }}
            />
            <Card style={{ backgroundColor: 'rgba(255, 255, 255, 0.3)', borderRadius: '15px', height: '300px' }}>

              <Form
                className="login-form"
                onSubmit={async (e) => {
                  e.preventDefault()
                  try {
                    // const { data: { login: token } } = await login({ variables: { email, password } })
                    // // login({ variables: { email, password } })
                    // setToken(token)
                    // history.push('/')
                    await firebase.login(email, password)
                    firebase.isInitialized().then((val) => {
                      setFirebaseInitialized(val)
                    })
                    history.push('/')
                  } catch (error) {
                    alert(error.message)
                  }
                }}
                className="login-form"
              >
                <Form.Item style={{ marginTop: '30px', marginBottom: '35px' }} validateStatus={usernameError ? 'error' : ''} help={usernameError || ''}>
                  {getFieldDecorator('email', {
                    rules: [{ required: true, message: 'Please input your username!' }],
                  })(
                    <Input
                      prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      size="large"
                      placeholder="Username"
                      id="email"
                      name="email"
                      autoComplete="email"
                      onChange={e => setEmail(e.target.value)}
                    />,
                  )}
                </Form.Item>
                <Form.Item style={{ marginBottom: '35px' }} validateStatus={passwordError ? 'error' : ''} help={passwordError || ''}>
                  {getFieldDecorator('password', {
                    rules: [{ required: true, message: 'Please input your Password!' }],
                  })(
                    <Input
                      prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      size="large"
                      type="password"
                      placeholder="Password"
                      id="password"
                      name="password"
                      autoComplete="current-password"
                      onChange={e => setPassword(e.target.value)}
                    />,
                  )}
                </Form.Item>
                <Form.Item>
                  <Button className="login-form-button" block type="primary" htmlType="submit" style={{ background: '#1897B7', borderColor: '#1897B7', fontSize: '20px' }} className="login-form-button" disabled={hasErrors(getFieldsError())}>
                    Log In
                  </Button>
                </Form.Item>
              </Form>

            </Card>
            <div style={{
              width: '100%', height: '1px', backgroundColor: 'rgba(255, 255, 255, 0.7)', marginTop: '70px',
            }}
            />

            <div
              className="Powered"
              style={{
                color: 'rgba(255, 255, 255, 0.8)',
                textAlign: 'center',
              }}
            >
              <b>Powered by Thai.run</b>
            </div>
          </Col>

        </Row>

      </Content>
    </Layout>

  )
}
LoginPage.propTypes = {
  history: PropTypes.shape(),
}
LoginPage.defaultProps = {
  history: {},
}

export default compose(
  Form.create(),
  withRouter,
)(LoginPage)
