import { Query, graphql, useQuery } from 'react-apollo'
import {
  Layout, Table,
  Menu, Breadcrumb, Icon, Button, Row, Col,
} from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

const { Header, Content, Sider } = Layout
const Province = props => (
  <Row>
    <Col span={12}>
      <Link to="/provincedetail">
        <Button size="large">จัดการข้อมูลจังหวัด</Button>
      </Link>
    </Col>
    {/* <Col span={12}>
      <Link to="/provinceboard">
        <Button size="large">province board</Button>
      </Link>
    </Col> */}
  </Row>
)

export default Province
