import React from 'react'
import { graphql, useQuery } from 'react-apollo'
import {
  Layout, Table,
} from 'antd'
import { Link } from 'react-router-dom'

import reportAmphoeCount from '../graphql/queries/reportCountAmphoe'

const AmphoeCount = (props) => {
  const columns = [
    {
      title: 'อำเภอ',
      dataIndex: 'amphoe',
      align: 'center',
      className: 'headercolor',
      render: record => (record === 'รวม' ? record : <Link to={`/provincesboard/${props.match.params.province}/${record}`}>{record}</Link>),
    },
    {
      title: 'จำนวนผู้สมัคร (คน)',
      dataIndex: 'count',
      key: '',
      defaultSortOrder: 'descend',
      align: 'center',
      className: 'headercolor',
      sorter: (a, b) => (a.isTotal || b.isTotal ? 0 : (a.count - b.count)),
      render: record => (`${record.toLocaleString() }`),
    },
    {
      title: 'ระยะวิ่งสะสม (กม.)',
      dataIndex: 'distance',
      key: '',
      align: 'center',
      className: 'headercolor',
      sorter: (a, b) => (a.isTotal || b.isTotal ? 0 : (a.distance - b.distance)),
      render: record => (`${record.toLocaleString(undefined, { minimumFractionDigits: 2 }) }`),
    },
  ]
  // const {
  //   data = { reportCountProvince: [] }, loading, error,
  // } = useQuery(reportProvinceCount, {
  //   variables: { slug: 'nscth' },
  // })
  const {
    data = { reportCountAmphoe: [] }, loading,
  } = useQuery(reportAmphoeCount, {
    variables: { slug: 'nscth', province: props.match.params.province },
  })
  const locale = {
    emptyText: 'fetching data',
  }
  let reportSumAmphoe = {
    amphoe: 'รวม',
    count: 0,
    distance: 0,
  }
  if (!loading) {
    const total = data.reportCountAmphoe.reduce((totalRecord, record) => ({
      count: totalRecord.count + record.count,
      distance: totalRecord.distance + record.distance,
    }), {
      count: 0,
      distance: 0,
    }) // { count, distance }
    console.log({ total })
    reportSumAmphoe = {
      amphoe: 'รวม', count: total.count, distance: total.distance, isTotal: true,
    }
  }
  const reportSumAllAmphoe = [...data.reportCountAmphoe, reportSumAmphoe]

  return (

    <Table
          // rowSelection={rowSelection}
      dataSource={reportSumAllAmphoe}
      columns={columns}
      locale={locale}
      rowKey="amphoe"
      pagination={false}
          // onChange={onTableChange}
      loading={loading}
      bordered
    />

  )
}
export default AmphoeCount
