import { graphql } from 'react-apollo'
import {
  Table,
} from 'antd'
import React, { Fragment } from 'react'
import { compose } from 'recompose'
import _ from 'lodash'

import RegistrationQuery from '../graphql/queries/getRegistration'
import RegistrationCountQuery from '../graphql/queries/getRegistrationCount'

const IndividualBoard = (props) => {
  const { registrationMany: { loading, registrationMany = [] }, registrationCount: { registrationCount = [] } } = props

  registrationMany.total = 0
  registrationMany.forEach((part, index, theArray) => {
    if (theArray[index].results === null) {
      theArray[index].results = 0
    } else {
      theArray[index].results = theArray[index].results.results.reduce((result, number) => result + number)
    }
    theArray.total += theArray[index].results
  }, registrationMany)

  const registrationManyDesc = _.sortBy(registrationMany, ['results']).reverse()
  const columns = [
    {
      title: 'ลำดับ',
      dataIndex: 'results',
      key: 'rank',
      render: record => (registrationManyDesc.findIndex(obj => obj.results === record) + 1),
    },
    {
      title: 'ชื่อ-สกุล',
      dataIndex: 'profile',
      key: 'name',
      render: record => (record.lastName ? `${record.name } ${ record.lastName}` : record.name),
    },
    // {
    //   title: 'จังหวัด',
    //   dataIndex: 'profile.province',
    //   key: 'province',
    // },
    {
      title: 'ระยะทางสะสม',
      dataIndex: 'results',
      key: 'distance',
      defaultSortOrder: 'descend',
      sorter: (a, b) => (a.results - b.results),
    },
  ]

  return (
    <Fragment>
      <h2>ระดับบุคคล</h2>
      {/* <p>ระยะสะสมรวมผู้สมัครทั้งหมดของรายการ : {registrationMany.total}</p>
          <p>จำนวนผู้สมัครรวมทั้งหมด : {registrationMany.length}</p>
          <p>ระยะสะสมเฉลี่ยต่อคนของผู้ร่วมกิจกรรม ทั้งหมด : {registrationMany.total/registrationMany.length}</p> */}
      <Table
        dataSource={registrationMany}
        columns={columns}
        rowKey="_id"
        pagination={{ pageSize: 10 }}
        size="middle"
            // onChange={this.onTableChange}
        loading={loading}
        bordered
      />
    </Fragment>

  )
}

export default compose(
  graphql(RegistrationQuery, { name: 'registrationMany', options: { variables: { slug: 'nscth' } } }),
  graphql(RegistrationCountQuery, { name: 'registrationCount', options: { variables: { slug: 'nscth' } } }),
)(IndividualBoard)
