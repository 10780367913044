import { graphql, useQuery } from 'react-apollo'
import {
  Button, Row, Col,
  Layout, Table,
} from 'antd'
import React, { Fragment, useEffect, useState } from 'react'

import usertype from '../data/userType.json'
import RegistrationCount from '../graphql/queries/getRegistrationCount'
import RegistrationallCount from '../graphql/queries/getRegistrationVhv'

const { Content } = Layout

const columns = [
  {
    title: 'สำนักงาน',
    dataIndex: 'department',
    key: 'department',
  },
  {
    title: 'ยอดผู้สมัคร',
    dataIndex: 'count',
    key: 'count',
    defaultSortOrder: 'descend',
    sorter: (a, b) => (a.count - b.count),
  },
]

const DepartmentReport = (props) => {
  const [position, setPosition] = useState(0)
  const [numload, setNumload] = useState(2)
  const [loadstatus, setLoadstatus] = useState(true)
  const [departcount, setDepartcount] = useState([])
  const [allcount, setAllcount] = useState([])
  const {
    data = { registrationCount: [] }, loading, error, fetchMore,
  } = useQuery(RegistrationCount, {
    variables: { slug: 'nscth' },
  })
  if (loading && loadstatus) {
    return <p>loading...</p>
  }

  const onFetchMore = async (depart) => {
    if (position === 0) {
      setAllcount(data.registrationCount)
    }
    setPosition(position + 1)
    if (position === numload) {
      setLoadstatus(false)
    }
    await fetchMore({
      query: RegistrationallCount,
      variables: { slug: 'nscth', userType: depart },
      fetchPolicy: 'cache-and-network',
      updateQuery: (previousResult, { fetchMoreResult }) => {
        setDepartcount(departcount => [...departcount, { department: depart, count: fetchMoreResult.registrationCount }])
      },
    })
  }

  if (loadstatus && !loading) {
    onFetchMore(usertype[position].name)
  }

  if (error) {
    console.log('error', error)
    return <p>error</p>
  }
  console.log(allcount)
  return (
    <Fragment>
      <p>จำนวนผู้สมัครทั้งหมด {allcount}</p>
      {departcount.map((d, i) => <p>{`${d.department } ${ d.count} คน`}</p>)}
    </Fragment>

  )
}
export default DepartmentReport
