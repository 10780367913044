import React, { Fragment, useEffect, useState } from 'react'
import { graphql, useQuery } from 'react-apollo'
import {
  Button, Row, Col,
  Layout, Table,
} from 'antd'
import { Link } from 'react-router-dom'

import department from '../data/department.json'
import reportProvinceCount from '../graphql/queries/reportCountProvince'

const {
  Header, Footer, Sider, Content,
} = Layout

const columns2 = [
  {
    title: 'จังหวัด',
    dataIndex: '_id',
    key: '',
    align: 'center',
    className: 'headercolor',
    sorter: (a, b) => (a.isTotal || b.isTotal ? 0 : (a._id.localeCompare(b._id))),
    render: record => (record === 'รวม' ? record : <Link to={`/provincesboard/${record}`}>{record}</Link>),
  },
  {
    title: 'จำนวนผู้สมัคร (คน)',
    dataIndex: 'count',
    key: '',
    defaultSortOrder: 'descend',
    align: 'center',
    className: 'headercolor',
    sorter: (a, b) => (a.isTotal || b.isTotal ? 0 : (a.count - b.count)),
    render: record => (`${record.toLocaleString() } `),
  },
  {
    title: 'ระยะวิ่งสะสม (กม.)',
    dataIndex: 'distance',
    key: '',
    align: 'center',
    className: 'headercolor',
    sorter: (a, b) => (a.isTotal || b.isTotal ? 0 : (a.distance - b.distance)),
    render: record => (`${record.toLocaleString(undefined, { minimumFractionDigits: 2 }) } `),
  },
]

const ProvinceReport = (props) => {
  const {
    data = { reportCountProvince: [] }, loading, error,
  } = useQuery(reportProvinceCount, {
    variables: { slug: 'nscth' },
  })
  let reportSumProvince = {
    _id: 'รวม',
    count: 0,
    distance: 0,
  }
  if (!loading) {
    const total = data.reportCountProvince.reduce((totalRecord, record) => ({
      count: totalRecord.count + record.count,
      distance: totalRecord.distance + record.distance,
    }), {
      count: 0,
      distance: 0,
    }) // { count, distance }
    // console.log({ total })
    reportSumProvince = {
      _id: 'รวม', count: total.count, distance: total.distance, isTotal: true,
    }
  }
  const reportCountSumProvince = [...data.reportCountProvince, reportSumProvince] // .concat(reportSumProvince)

  return (

    <Table
      // scroll={{ y: 'calc(100vh - 4em)' }}
          // rowSelection={rowSelection}
      dataSource={reportCountSumProvince}
      columns={columns2}
      rowKey="_id"
      pagination={false}
      // size="small"
          // onChange={onTableChange}
      loading={loading}
      bordered
    />

  )
}
export default ProvinceReport
