import { graphql, useQuery } from 'react-apollo'
import {
  Button, Row, Col,
  Layout, Table,
} from 'antd'
import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { mod } from 'mathjs'

import province from '../data/provinces.json'
import department from '../data/department.json'
import RegistrationProvinceCount from '../graphql/queries/getRegistrationProvinceCount'
import RegistrationallCount from '../graphql/queries/getRegistrationAllCount'
import RegistrationaCount from '../graphql/queries/getRegistrationVhv'

import DepartmentReport from './departmentReport'
import AllCount from './allCount'

const { Content } = Layout

const columns = [
  {
    title: 'จังหวัด',
    dataIndex: 'province',
    key: 'province',
  },
  {
    title: 'ยอดผู้สมัคร',
    dataIndex: 'count',
    key: 'count',
    defaultSortOrder: 'descend',
    sorter: (a, b) => (a.count - b.count),
  },
]

const LeaderBoard = (props) => {
  const [position, setPosition] = useState(0)
  const [numload, setNumload] = useState(16)
  const [loadstatus, setLoadstatus] = useState(true)
  const [procount, setProcount] = useState([])
  const {
    data = { registrationCount: [] }, loading, error, fetchMore,
  } = useQuery(RegistrationProvinceCount, {
    variables: { slug: 'nscth', province: '' },
  })
  // const {
  //   data2 = { registrationCount: [] },
  // } = useQuery(RegistrationallCount, {
  //   variables: { slug: 'nscth' },
  // })
  if (loading && loadstatus) {
    return <p>loading...</p>
  }
  const onFetchMore = async (provi) => {
    setPosition(position + 1)
    if (position === numload) {
      setLoadstatus(false)
    }
    await fetchMore({
      query: RegistrationProvinceCount,
      variables: { slug: 'nscth', province: provi },
      fetchPolicy: 'cache-and-network',
      updateQuery: (previousResult, { fetchMoreResult }) => {
        // ...previousResult,
        // // Add the new matches data to the end of the old matches data.
        // registrationCount: [
        //   previousResult.registrationCount,
        //   fetchMoreResult.registrationCount,
        // ],
        setProcount(procount => [...procount, { province: provi, count: fetchMoreResult.registrationCount }])
      },
    })
  }

  if (loadstatus && !loading) {
    onFetchMore(province[position].name.th)
  }

  if (error) {
    console.log('error', error)
    return <p>error</p>
  }

  // useEffect(() => {
  //   province.forEach(async (item) => {
  //     await fetchMore({
  //       query: RegistrationProvinceCount,
  //       variables: { slug: 'nscth', province: item.name },
  //       fetchPolicy: 'cache-and-network',
  //       updateQuery: (previousResult, { fetchMoreResult }) => ({
  //         ...previousResult,
  //         registrationCount: [
  //           ...previousResult.registrationMany,
  //           ...fetchMoreResult.registrationMany,
  //         ],
  //       }),
  //     })
  //   })
  // }, data.registrationCount)
  // <Fragment>
  //   <Row>
  //     <Col span={8}>
  //       <Link to="/individuallead">
  //         <Button size="large">ระดับบุคคล</Button>
  //       </Link>
  //     </Col>
  //     <Col span={8}>
  //       <Link to="/departmentselect">
  //         <Button size="large">ระดับหน่วยงาน</Button>
  //       </Link>
  //     </Col>
  //     {/* <Col span={8}>
  //       <Button size="large">ระดับจังหวัด</Button>
  //     </Col> */}
  //     <Col span={8}>
  //       <Link to="/provinceboard">
  //         <Button size="large">province board</Button>
  //       </Link>
  //     </Col>
  //   </Row>
  // </Fragment>
  const onTableChange = (pagination) => {
    // console.log('params', pagination, filters, sorter, extra, skip)
    setNumload(numload + 20)
    if (pagination.current < 9 && position < 77) {
      setLoadstatus(true)
      onFetchMore(province[position].name.th)
    }
    // console.log(pagination.current)
    // onFetchMore()
  }

  // console.log(procount)
  return (

    <Layout>
      <Content>

        <div style={{ background: '#fff', padding: 24, minHeight: 'calc(100vh - 64px - 69px)' }}>
          {/* <Button
            style={{ marginRight: '10px' }}

          >
            {data2.registrationCount}
          </Button> */}
          <AllCount />
          <Table
              // rowSelection={rowSelection}
            dataSource={procount}
            columns={columns}
            rowKey="province"
            pagination={{ pageSize: 10 }}
            size="middle"

            onChange={onTableChange}
            // loading={loadstatus}
            bordered
          />
        </div>
        <DepartmentReport />
      </Content>
    </Layout>

  )
}
export default LeaderBoard
