import { Query, graphql, useQuery } from 'react-apollo'
import {
  Layout, Table,
  Menu, Breadcrumb, Icon, Button, Row, Col,
} from 'antd'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import * as compose from 'lodash.flowright'
import _ from 'lodash'

import provinces from '../data/provinces.json'
import RegistrationQuery from '../graphql/queries/getRegistration'

const { Header, Content, Sider } = Layout
const ProvinceBoard = (props) => {
  const { registrationMany: { loading, registrationMany = [] } } = props
  const regiscount = _(registrationMany)
    .groupBy('profile.province')
    .map((items, name) => ({ name, count: items.length }))
    .value()

  const groupprovince = _(registrationMany)
    .groupBy('profile.province')
    .map((items, name) => ({
      name, count: items.length, items, sums: _.sumBy(items, (o) => { if (o.results !== null) { return _.sumBy(o.results.results) } }),
    }))
    .value()
  console.log(registrationMany)
  console.log(groupprovince)

  // groupprovince.forEach((e) => {
  //   e.items.forEach((items) => {
  //     if (items.results === null) {
  //       console.log(null)
  //     } else {

  //     }
  //   })
  // })

  console.log(_(groupprovince)
    .map((items, name) => ({ name: items.name, count: items.length, sums: _.sumBy(items.items, (o) => { if (o.results !== null) { return _.sumBy(o.results.results) } }) }))
    .value(),
  )

  /* console.log(_(registrationMany)
  .groupBy('profile.province')
  .map((items, name) => ({ name, count: items.length, items }))
  .value()) */

  const columns = [

    {
      title: 'จังหวัด',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'จำนวนผู้สมัคร',
      dataIndex: 'count',
      key: 'count',
      defaultSortOrder: 'descend',
      sorter: (a, b) => (a.count - b.count),
    },
    {
      title: 'ค่าเฉลี่ยระยะการวิ่งสะสมต่อประชากรทั้งหมดในพื้นที่ (KM)',
      dataIndex: 'sums',
      key: 'distance',
      defaultSortOrder: 'descend',

    },
  ]
  const onTableChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra)
  }

  return (
    <Fragment>
      <h2>ภาพรวมยอดผู้สมัคร</h2>
      <Table
        dataSource={groupprovince}
        columns={columns}
        rowKey="name"
        pagination={{ pageSize: 15 }}
        size="middle"

        onChange={onTableChange}
        loading={loading}
        bordered
      />
    </Fragment>

  )
}

ProvinceBoard.propTypes = {
  registrationMany: PropTypes.shape({
    error: PropTypes.object,
    loading: PropTypes.bool,
    registrationMany: PropTypes.array,
    vrResultsOne: PropTypes.array,
    refetch: PropTypes.func,
  }).isRequired,
}
ProvinceBoard.defaultProps = {}

export default graphql(RegistrationQuery, { name: 'registrationMany', options: { variables: { slug: 'nscth' } } })(ProvinceBoard)
